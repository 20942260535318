import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { getContentType } from '../common/Common';
import { getTranslation } from '../common/Translation';
import Image from './Image'
import Link from './Link';
import useFetchDocument from '../hooks/useFetchDocument';

interface TimelineItemProps {
  documentId: number,
  display?: string,
  title?: string,
  subtitle?: string,
  image?: any,
  textBackground?: "block" | "shadow" | "none" | undefined
}

const Item = styled.li`
  float: left;
  height: ${p => p.theme.scaleFactor * 250}px;
  overflow-y: visisble;
  padding: 0 ${p => p.theme.scaleFactor * 60}px;
  width: 25vw;
  margin-right: 120px;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateZ(0);

  &:active {
    filter: brightness(0.8);
  }

  &:last-child {
    padding-right: ${p => p.theme.scaleFactor * 50}px;
  }

  &.noImage {
    padding-top: ${p => p.theme.scaleFactor * 100}px;
    min-width: 35vw;
  }

  & .shadow {
    text-shadow: 0 0 4px #000, 0 -5px 20px rgba(0,0,0,.5), 0 0 40px rgba(0,0,0,.5);
    background-color: inherit;
  }

  & .none {
    background-color: inherit;
  }
`

const StyledImage = styled(Image)`
  height: 25vh;
  width: 25vw;
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
`

const Title = styled.span`
  margin: 10px 0 0;
  color: ${p => p.theme.textColor};
  background-color: rgba(0,0,0,.4);
  font-size: 1.4em;
  font-weight: 700;
  text-shadow: 4px 4px 90px #000;
  max-height: 4em;
  border-radius: 1px;
  padding: 5px 10px;
  display: table;
  width: 25vw;
  line-height: ${p => p.theme.scaleFactor * 2.3}rem;
`

const Subtitle = styled.span`
  margin: 15px 0 0;
  color: #fff;
  background-color: rgba(0,0,0,.4);
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 4px 4px 90px #000;
  border-radius: 1px;
  padding: 10px 10px;
  display: inline-block;
  width: 25vw;
`

/**
 * Render an item for the timeline view
 * @returns {JSX.Element} Component template
 */
const TimelineItem: FC<TimelineItemProps> = ({documentId, display, title, subtitle, image, textBackground}) => {
  const { currentLanguage } = useContext(AppContext);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemSubtitle, setItemSubtitle] = useState<any>(null);
  const [contentType, setContentType] = useState<string>("");
  const [data] = useFetchDocument(documentId);
  const [itemMedia, setItemMedia] = useState<any>(null);

  // Should custom image or document main image be used?
  useEffect(() => {
    const imageData = image?.mediaId ? image : (data?.content?.general?.image || data?.content?.background?.image)
    setItemMedia(imageData);
  }, [image, data])

  // Get item type
  useEffect(() => {
    setContentType(getContentType(data))
  }, [data, setContentType]);

  // Get title and subtitle
  useEffect(() => {
    if (data) {
      setItemTitle(getTranslation(title, currentLanguage)?.length > 0 ? getTranslation(title, currentLanguage) : getTranslation(data?.title, currentLanguage));
      setItemSubtitle(getTranslation(subtitle, currentLanguage)?.length > 0 ? getTranslation(subtitle, currentLanguage) : null);
    }
  }, [title, subtitle, currentLanguage, data]);

  // Do not display link if not available in current language
  if (data?.status && !(data?.title?.[currentLanguage || "no"] || data?.content?.content?.blocks?.[currentLanguage || "no"])) { return null; }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <Item className={(display === "text") ? "noImage" : ""}>
      <Link id={documentId} presentationType={contentType}>
        <>
          {(!(display === "text")) && (<StyledImage media={contentType && itemMedia} backgroundColor={`transparent`}/>)}
          {!(display === "thumbnail") && (
            <>
              {itemTitle && (<Title className={textBackground}>{itemTitle}</Title>)}
              {itemSubtitle && (<Subtitle className={textBackground}>{itemSubtitle}</Subtitle>)}
            </>
          )}
        </>
      </Link>
    </Item>
  );
}

export default TimelineItem;
