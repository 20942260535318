import { API_PREFIX, KULTURIO_API_KEY, KULTURIO_API_URL, MINNE_API_URL_NO, MINNE_API_URL_SV } from "../Settings";

/**
 * Fetch data from API
 * @param {string} url Fetch URL
 * @returns {object} API data
 */
export const fetchKulturioData = (url: string) => {  
  return fetch(`${API_PREFIX}${url}`, {
    method: "GET",
    headers: {
      "x-api-key": KULTURIO_API_KEY
    }
  }).then(response => {
    if (response?.status !== 200) {
      return Promise.reject({message: "error.fetchError", error: response?.status});
    }
    return response?.json();
  }).then(data => {
    return data;
  }).catch((e: TypeError) => {
    // We need to handle net::ERR_NETWORK_CHANGED, but this error is not exposed in the error object
    if (e?.message?.includes("Failed to fetch") && API_PREFIX?.length > 5) {
      console.error("Network error. Reload in 30 seconds.");
      setTimeout(() => {
        window.location.reload();
      }, 30000)
    }

    if (!navigator.onLine) {
      return Promise.reject({message: "error.noNetwork", error: e});
    } else {
      return Promise.reject({message: "error.fetchError", error: e});
    }
  });
}

/**
 * Get document
 * @param {string|number} id Document ID
 * @param {number|undefined} schemaId Schema
 * @param {boolean} oldIdLookup Schema
 * @returns {object} Full document
 */
export const fetchDocument = async (id: string | number | undefined, options: any={}) => {
  if (id === "undefined" || typeof id === "undefined" || isNaN(Number(id))) { return null; }
  
  let url: string;
  if (options?.schemaId) {
    url = `${KULTURIO_API_URL}documents/${id}?schema_ids=${options?.schemaId || ""}`;
  } else {
    url = `${KULTURIO_API_URL}documents/${id}`;
  }

  return await fetchKulturioData(url);
}

/**
 * Get media object
 * @param {string|number} id Media ID
 * @param {boolean} includeOriginalUrl Should original file URL be included?
 * @returns {object} Media metadata
 */
export const fetchMedia = async (id: string | number, options: any = {}) => {
  if (id === "undefined" || typeof id === "undefined") { return null; }
  const url = `${KULTURIO_API_URL}media/${id}`;
  const mediaData = await fetchKulturioData(url);

  if (options?.includeOriginalUrl && mediaData.media_type === "video") {
    const originalUrl = await fetchOriginalMediaUrl(id);
    return {...mediaData, ...{originalUrl: originalUrl}}
  } else if (mediaData.media_type === "video" && mediaData?.src) {
      const domain = (new URL(mediaData?.src)).origin;
      const transcodeUrl = `${domain}/streaming/adaptive/{id}/{id}.mp4?mmid={id}&a=none`.replaceAll("{id}", mediaData.identifier) 
      return {...mediaData, ...{src: transcodeUrl}}
  } else {
    return mediaData;
  }
}

/**
 * Get media object
 * @param {string|number} id Media ID
 * @param {object} options Options
* @returns {object} Media metadata
 */
export const fetchOriginalMediaUrl = async (id: string | number, options: any={}) => {
if (id === "undefined" || typeof id === "undefined") { return null; }
const url = `${KULTURIO_API_URL}media/${id}/download`;

const mediaData = await fetchKulturioData(url);
return mediaData?.url || null;
}

/**
 * Query DM
 * @param {string} url Fetch url
 * @param url 
 */
export const fetchDMContent = (url: string) => {
  return fetch(`${API_PREFIX}${url}`, {
    method: "GET",
  }).then(response => response?.json()
  ).then(data => {
    return data;
  }).catch(e => {
    if (!navigator.onLine) {
      return Promise.reject({message: "error.noNetwork", error: e});
    } else {
      return Promise.reject({message: "error.fetchError", error: e});
    }
  });
}

/**
 * Query Minne API
 * @param {string} url URL to query
 * @param {string} country Defines if norwegian (no) or swedish (sv) API should be used  
 */
  export const fetchMinneContent = async (url: string, country: string) => {
    return fetch(`${country === "no" ? MINNE_API_URL_NO : MINNE_API_URL_SV}${url}`, {
    method: "GET"
  }).then(response => response?.json()
  ).then(data => {
    return data;
  }).catch(e => {
    if (!navigator.onLine) {
      return Promise.reject({message: "error.noNetwork", error: e});
    } else {
      return Promise.reject({message: "error.fetchError", error: e});
    }
  });
}
