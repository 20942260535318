import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as LoaderSpinner } from "../assets/loader.svg";

interface LoadingOverlayProps {
  isOpen: boolean
}

const LoadingOverlayWrapper = styled.div`
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${p => p.theme.backgroundColor};

  & svg {
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & svg path {
    fill: ${p => p.theme.accentColor};
  }
`;

/**
 * Render a full screen loading view
 * @returns {JSX.Element} Component template
 */
const LoadingOverlay: FC<LoadingOverlayProps> = ({isOpen}) => {
  return (
    <>
      {isOpen && (<LoadingOverlayWrapper><LoaderSpinner/></LoadingOverlayWrapper>)}
    </>
  );
}

export default LoadingOverlay;

