import { FC } from 'react';
import styled from 'styled-components';

interface BlockAnchorProps {
  id: string
}

const Anchor = styled.div`
  visibility: hidden;
`;

/**
 * Render an article anchor
 * @returns {JSX.Element} Component template
 */
const BlockAnchor: FC<BlockAnchorProps> = ({id}) => {
  return <Anchor id={id}/>
}

export default BlockAnchor;