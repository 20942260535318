import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getContentType } from '../common/Common';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import Image from './Image';
import Link from './Link';
import useFetchDocument from '../hooks/useFetchDocument';

interface ListItemProps {
  documentId: number,
  title?: string,
  subtitle?: string,
  image?: any,
  cellSize?: any,
  textPosition?: string,
  titleColor?: string,
  subtitleColor?: string,
  titleSize?: string,
  subtitleSize?: string,
  textBackground?: string,
  cellBackgroundColor?: string
}

const ListElement = styled.li.attrs((p: any) => ({
  rows: p.rows || 1,
  columns: p.columns || 1
}))`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: ${p => p.theme.elementShadow}; */
  grid-column-end: span ${p => p.columns};
  grid-row-end: span ${p => p.rows};

  & a {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ListTitleWrapper = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  padding: 20px 30px;
  display: inline-table;
  width: 100%;
  word-break: break-word;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &.top-right {
    top: 0;
    left: unset;
    right: 0;
    text-align: right;
  }

  &.mid-left {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  &.mid-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &.mid-right {
    top: 50%;
    transform: translateY(-50%);
    left: unset;
    right: 0;
    text-align: right;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }

  &.bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &.bottom-right {
    bottom: 0;
    left: unset;
    right: 0;
    text-align: right;
  }

  &.bg-shadow span {
    text-shadow: 0 0 4px #000, 0 -5px 20px rgba(0,0,0,.5), 0 0 40px rgba(0,0,0,.5);
  }

  &.bg-block span {
    background: #000;
    padding: 5px 10px;
    display: inline-block;
  }

  &.bg-gradient {
  }

  &.bg-hidden {
    display: none;
  }

  &.bg-outline {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }
`

const ListTitle = styled.span.attrs((p: any) => ({
  size: p.size || "1.5em",
  color: p.color || "#ffffff"
}))`
  display: block;
  font-size: ${p => p.size};
  color: ${p => p.color};
  font-weight: bold;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
`

const ListSubTitle = styled.span.attrs((p: any) => ({
  size: p.size || "1em",
  color: p.color || "#ffffff"
}))`
  display: block;
  font-size: ${p => p.size};
  color: ${p => p.color};
  text-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
`

const ListImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

/**
 * Render an item for the list view
 * @returns {JSX.Element} Component template
 */
const ListItem: FC<ListItemProps> = ({documentId, image, title, subtitle, cellSize, textPosition, titleColor, subtitleColor, titleSize, subtitleSize, textBackground, cellBackgroundColor}) => {
  const { currentLanguage } = useContext(AppContext);
  const [itemTitle, setItemTitle] = useState<any>(null);
  const [itemSubtitle, setItemSubtitle] = useState<any>(null);
  const [itemMedia, setItemMedia] = useState<any>(null);
  const [contentType, setContentType] = useState<string>("");
  const [mappedCellSize, setMappedCellsize] = useState<any>(null);
  const [data] = useFetchDocument(documentId);

  // Should custom image or document main image be used?
  useEffect(() => {
    const imageData = image?.mediaId ? image : (data?.content?.general?.image || data?.content?.background?.image)
    setItemMedia(imageData);
  }, [image, data])

  // Get content type of a presentation or article
  useEffect(() => {
    setContentType(getContentType(data))
  }, [data]);

  // Get title and subtitle
  useEffect(() => {
    if (data) {
      setItemTitle(getTranslation(title, currentLanguage)?.length > 0 ? getTranslation(title, currentLanguage) : getTranslation(data?.title, currentLanguage));
      setItemSubtitle(getTranslation(subtitle, currentLanguage)?.length > 0 ? getTranslation(subtitle, currentLanguage) : null);
    }
  }, [title, subtitle, currentLanguage, data]);

  // Get the item cellsize. Can be received as a string ("2x3") or an object ({columns: 2, rows: 3})
  useEffect(() => {
    if (typeof cellSize === "string") {
      setMappedCellsize({
        columns: Number(cellSize?.split("x")?.[0] || 1),
        rows: Number(cellSize?.split("x")?.[1] || 1)
      });
    } else {
      // The cellsize values were switched in the old version.
      setMappedCellsize({
        columns: Number(cellSize?.rows || 1),
        rows: Number(cellSize?.columns || 1)
      });
    }
  }, [cellSize]);


  // Do not display link if not available in current language
  if (data?.status && !(data?.title?.[currentLanguage || "no"] || data?.content?.content?.blocks?.[currentLanguage || "no"])) { return null; }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <ListElement rows={mappedCellSize?.rows || 1} columns={mappedCellSize?.columns || 1}>
      <Link id={documentId} presentationType={contentType}>
      <>
          {(!(itemMedia?.filters?.brightness === 0)) && <ListImage media={contentType && itemMedia} backgroundColor={cellBackgroundColor}/>}
          <ListTitleWrapper className={`${textPosition} bg-${textBackground}`}>
            {itemSubtitle && (<ListSubTitle size={subtitleSize} color={subtitleColor}>{itemSubtitle}</ListSubTitle>)}
            <ListTitle size={titleSize} color={titleColor}>{itemTitle}</ListTitle>
          </ListTitleWrapper>
        </>
      </Link>
    </ListElement>
  );
}

export default ListItem;
