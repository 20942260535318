import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image from './Image';
import Link from './Link';

interface ImageMapPopupProps {
  documentId: number,
  contentType: string,
  title: string,
  subtitle?: string,
  image?: any,
  position: { x: number, y: number },
  markerColor?: string,
  onClick?: () => void
}

const PopupWrapper = styled.div.attrs((p: any) => ({
  noImage: p.noImage || false
}))`
  position: absolute;
  z-index: 12;
  transform-origin: -10px 50px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  min-width: ${p => p.theme.scaleFactor * 250}px;
  min-height: 75px;
  top: ${p => p.noImage ? "-20px" : "-50px"};
  left: 70px;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;

  &::before {
    z-index: 11;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: ${p => p.noImage ? "35px" : "65px"};
    left: -10px;
    border: 10px solid #fff;
    border-color: transparent transparent #fff #fff;
    transform: rotate(45deg);
  }

  &.left {
    right: 70px;
    left: unset;
    transform-origin: calc(100% + 10px) 90px;
  }

  &.left::before {
    left: unset;
    right: -10px;
    border-color: #fff #fff transparent transparent;
  }

  &.bottom {
    top: unset;
    bottom: ${p => p.noImage ? "-20px" : "-50px"};
  }

  &.bottom::before {
    top: unset;
    bottom: ${p => p.noImage ? "35px" : "70px"};
  }

  &.visible {
    /*transform: scale(1);*/
    opacity: 1;
    transition: all .5s;
    pointer-events: all;
  }

  & a, & .link {
    padding: ${p => p.theme.scaleFactor * 15}px;
    /* scale: ${p => 1 / p.theme.scaleFactor * .9}; */
  }
`

const StyledImage = styled(Image)`
  width: ${p => p.theme.scaleFactor * 250}px;
  height: ${p => p.theme.scaleFactor * 160}px;
  margin-bottom: 20px;
  object-fit: cover;
`

const Title = styled.span`
  color: #000;
  font-weight: bold;
  font-size: 1.1em;
  display: block;
`

const Subtitle = styled.span.attrs((p: any) => ({
  accentColor: p.accentColor || undefined
}))`
  color: #000;
  font-size: 1em;
  display: block;
  letter-spacing: .8px;

  &::before {
    position: relative;
    content: "";
    background-color: ${p => p.accentColor || p.theme.accentColor};
    height: 5px;
    margin: 8px 0;
    width: 30px;
    display: block;
    color: #a50b30;
  }
`

/**
 * Render a popup modal for the imagemap view
 * @returns {JSX.Element} Component template
 */
const ImageMapPopup: FC<ImageMapPopupProps> = ({documentId, contentType, title, subtitle, image, position, markerColor, onClick}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  // Define direction of arrow based on position
  useEffect(() => {
    let arrowPosition: string[] = [];
    if (position?.x > 800) { arrowPosition.push("left"); }
    if (position?.y > 600) { arrowPosition.push("bottom"); }

    if (popupRef.current) {
      popupRef.current.classList.remove("left");
      popupRef.current.classList.remove("bottom");
      arrowPosition?.forEach((pos: string) => {
        popupRef?.current?.classList?.add(pos);
      });
    }
  }, [position]);

  return (
    <PopupWrapper ref={popupRef} onClick={onClick} id={`imagemappopup-${documentId}`} className={`imagemappopup`} noImage={!Boolean(image?.url)}>
      <Link id={documentId} presentationType={contentType}>
        <>
          {image?.url && (<StyledImage media={image}/>)}
          {title?.length > 1 && (<Title>{title}</Title>)}
          {subtitle && subtitle?.length > 1 && (<Subtitle accentColor={markerColor}>{subtitle}</Subtitle>)}
        </>
      </Link>
    </PopupWrapper>
  );
}

export default ImageMapPopup;
