import { FC } from 'react';
import styled from 'styled-components';

interface BlockContainerProps {
  children: JSX.Element | JSX.Element[],
  className?: string,
}

const Block = styled.div`
  clear: both;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  max-width: ${p => p.theme.blockWidth};
  margin: 0 auto ${p => p.theme.blockMargin};

  &.slide {
    width: 100%;
    padding: 0;
    max-width: 100%;
  }
`;

/**
 * Render an article block container
 * @returns {JSX.Element} Component template
 */
const BlockContainer: FC<BlockContainerProps> = ({children, className}) => {

  return (
    <Block className={className}>
      {children}
    </Block>
  );
}

export default BlockContainer;