import { FC, useCallback, useContext, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components";
import { AppContext } from "../contexts/AppContext";

interface VolumeControlProps {
  x?: number,
  y?: number,
  close: any
}

const FadeIn = keyframes`
  from {
    opacity: 0
  } to {
    opacity: 1
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 100001;
  overscroll-behavior: contain;
  animation: ${FadeIn} 0.5s;
`;

const Wrapper = styled.div.attrs((p: any) => ({
  x: p.x,
  y: p.y
}))`
  padding: ${p => p.theme.scaleFactor * 40}px;
  height: ${p => p.theme.scaleFactor * 300}px;
  border-radius: 20px;
  position: absolute;
  top: ${p => p.y}px;
  left: ${p => p.x}px;
  transform: translate(-50%, 20px);
  z-index: 100002;
  color: ${p => p.theme.textColor};
  background-color: ${p => p.theme.backgroundColor};
  border-radius: 5px;
  transition: top 0.2s linear;
  box-shadow: -8px -1px 52px -4px rgba(0, 0, 0, .6);
  display: ${p => p.x ? "block" : "none"};
`;

const VolumeWrapper = styled.div`
  position: absolute;
  height: ${p => p.theme.scaleFactor * 225}px;
  background-color: #ddd;
  width: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 100002;
  border-radius: 2px;

  &::before {
    padding: 0 50px;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    content: "";
    transform: translate(-50%, -50%);
    height: 300px;
    border-radius: 2px;
    display: block;
  }
`;

const Volume = styled.div`
  position: absolute;
  bottom: 0;
  width: 8px;
  background-color: ${p => p.theme.accentColor};
  max-height: 100% !important;
  min-height: 0% !important;
`;

/**
 * Render the volume control
 * @returns {JSX.Element} Component template
 */
const VolumeControl: FC<VolumeControlProps> = ({x, y, close}) => {
  const volumeRef = useRef<HTMLDivElement>(null);
  const volumeWrapperRef = useRef<HTMLDivElement>(null);
  const { globalVolume, setGlobalVolume } = useContext(AppContext);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  /**
   * Set volume based on click
   */
  const setVolume = useCallback((e: any) => {
    let y = 0;

    // Calculate position based on touch/mouse event
    if (e?.changedTouches) {
      const rect = e.target.getBoundingClientRect();
      y = e?.changedTouches?.[0]?.pageY - rect?.top;
    } else {
      y = e?.nativeEvent?.offsetY;
    }

    // Update global volume state
    if (volumeWrapperRef.current) {
      let clickedPositionPercentage = (volumeWrapperRef.current.clientHeight - y) / volumeWrapperRef.current.clientHeight;
      if (clickedPositionPercentage < 0) { clickedPositionPercentage = 0; }
      if (clickedPositionPercentage > 1) { clickedPositionPercentage = 1; }

      if (!isNaN(clickedPositionPercentage)) {
        setGlobalVolume(clickedPositionPercentage);
      }
    }
    e?.stopPropagation();
  }, [setGlobalVolume]);

  /**
   * Hide volume control on overlay click
   */
  const hideControl = useCallback(() => {
    document.body.style.overflow = 'auto';
    close && close();
  }, [close]);

  if (!x || !y) {
    return null;
  }

  return (
    <Overlay onClick={hideControl}>
      <Wrapper x={x} y={y}>
        <VolumeWrapper ref={volumeWrapperRef} onClick={setVolume} onTouchMove={setVolume}>
          <Volume ref={volumeRef} style={{height: `${(globalVolume || 0) * 100}%`}}/>
        </VolumeWrapper>
      </Wrapper>
    </Overlay>
  );
}

export default VolumeControl;
