import { FC, useCallback, useEffect, useState } from 'react';
import { fetchDMContent } from '../services/Api';
import { DM_OBJECT_URL, DM_API_KEY } from '../Settings';
import useFetchMedia from '../hooks/useFetchMedia';
import BlockText from './BlockText';
import BlockImageSlideshow from './BlockMedia_Slideshow';
import { BlockAudioItem, BlockAudioWrapper } from './BlockAudio';

interface BlockDMProps {
  element?: any
}

/**
 * Render a single DigitaltMuseum element block
 * @returns {JSX.Element} Component template
 */
const BlockDMSingle: FC<BlockDMProps> = ({element}) => {
  const [dmMetadata, setDmMetadata] = useState<any>();
  const [parsedMetadata, setParsedMetadata] = useState<any>([]);
  const [mediaObject] = useFetchMedia({mediaId: element?.mediaId});

  // Remove "?dimensions" part of image URLs
  const cleanImageUrl = useCallback((imageUrl: string) => {
    return imageUrl.replace(/[?]dimension\=[^.]+&?/, ""); //eslint-disable-line
  }, []);

  // Get object metadata from DM
  useEffect(() => {
    let isSubscribed = true;

    if (!mediaObject?.identifier) { return; }
      fetchDMContent(`${DM_OBJECT_URL.replace("{id}", mediaObject?.identifier)}&api.key=${DM_API_KEY}`).then(data => {
        isSubscribed && setDmMetadata(data);
    }).catch(e => {
      console.error(`Could not fetch data from DM`);
    });

    return () => { isSubscribed = false };
  }, [mediaObject]);

  // Extract DM metadata
  useEffect(() => {
    let fields: any[] = [];

    if (element?.includeFields) {
      element?.includeFields?.forEach((element: any) => {
        switch(element?.field) {
          case "title":
            fields.push({type: "text", text: dmMetadata?.title || undefined});
            break;
          case "motif":
            fields.push({type: "text", text: dmMetadata?.motif?.description || undefined});
            break;
          case "history":
            fields.push({type: "text", text: dmMetadata?.history?.description || undefined});
            break;
          case "description":
            fields.push({type: "text", text: dmMetadata?.description || undefined});
            break;
          case "owner":
            fields.push({type: "text", text: dmMetadata?.partOfCollection?.owner?.name || undefined});
            break;
          case "audio":
            dmMetadata?.files?.forEach((file: any, i: number) => {
              if ((file?.fileType || "").toLowerCase() === "audio" || (file?.fileType === null && dmMetadata?.type === "Audio")) {
                fields.push({type: "audio", dmsId: file?.mediaId || undefined});
              }
            })
            break;
          case "video":
            dmMetadata?.files?.forEach((file: any, i: number) => {
              if ((file?.fileType || "").toLowerCase() === "video" || (file?.fileType === null && dmMetadata?.type === "Video")) {
                fields.push({type: "video", dmsId: file?.mediaId || undefined});
              }
            })
            break;
          case "images":
            let images: any[] = [];
            element?.imageIds?.forEach((imageId: any) => {
              dmMetadata?.images && dmMetadata?.images.forEach((image: any, i: number) => {
                if (imageId === image?.mediaId || imageId === i) {
                  images.push(image);
                }
              });
            });
            fields.push({type: "images", media: images, owner: dmMetadata?.partOfCollection?.owner?.name || undefined});
            break;
        }
      });
    }

    dmMetadata && setParsedMetadata(fields);
  }, [dmMetadata, element]);

  /**
   * Collect list of images from field
   * @param {object} field Field to traverse
   * @returns {array} Array of media items
   */
  const collectImageList = useCallback((field) => {
    let imageList: any[] = [];

    field?.media?.forEach((image: any) => {
      const credit = [
        {credit_type_id: 1, name: image?.photographer},
        {credit_type_id: 6, name: field?.owner}
      ]

      imageList.push({
        url: cleanImageUrl(image?.url),
        media_type: "dm",
        credits: credit
      });
    });

    return imageList;
  }, [cleanImageUrl]);

  if (parsedMetadata?.length === 0) { return null }

  return (
    <>
      { parsedMetadata?.map((field: any, i: number) => {
        switch(field?.type) { 
          case "text":
            if (!field?.text) { return null; }
            return <BlockText key={`block${i}`} text={field?.text}/>
          case "images":
            if (!field?.media) { return null; }
            return <BlockImageSlideshow key={`block${i}`} elements={collectImageList(field)}/>
          case "video":
            if (!field?.dmsId) { return null; }
            return <BlockImageSlideshow key={`block${i}`} elements={[{ dmsId: field?.dmsId, media_type: "video" }]}/>
          case "audio":
            if (!field?.dmsId) { return null; }
            return <BlockAudioWrapper key={`block${i}`}><BlockAudioItem key={`block${i}`} media={{ dmsId: field?.dmsId, media_type: "audio" }}/></BlockAudioWrapper>
          default:
            return null;
        }
      })}
    </>
  );
}

export default BlockDMSingle;