import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import BackgroundImage from '../components/BackgroundImage';
import ListItem from '../components/ListItem';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import { useTranslation } from 'react-i18next';
import ErrorMessageView from '../components/ErrorMessage';
import useFetchDocument from '../hooks/useFetchDocument';
import { detectDomChanges } from '../common/Common';

const ListWrapper = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  list-style: none;
  margin: 0 ${p => p.theme.scaleFactor * 70}px ${p => p.theme.scaleFactor * 90}px;
  padding: ${p => p.theme.scaleFactor * 140}px 0 0px;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: start;
  align-content: space-evenly;
  grid-template-areas: none;
  grid-auto-flow: row dense; // column dense
  gap: 12px;
  scroll-snap-type: x mandatory;
  touch-action: pan-x;
  overflow: hidden;
  height: calc(100% - ${p => p.theme.scaleFactor * 60}px);

  &.snapScroll {
    overflow: scroll;
    scrollbar-width: auto;
    @supports not (-webkit-touch-callout: none) {
      @supports not (-moz-appearance:none) {
        height: 100%;
      }
    }
  }

  &.snapScroll li:nth-child(1n+1) {
    scroll-snap-align: end;
  }

  &.snapScroll::-webkit-scrollbar {
    @supports not (-webkit-touch-callout: none) {
      height: 90px;
    }
  }

  &.snapScroll::-webkit-scrollbar-thumb {
    border-bottom: 50px solid transparent;
    border-top: 30px solid transparent;
    background-color: ${p => p.theme.accentColor};
    background-clip: padding-box;
  }

  &.snapScroll::-webkit-scrollbar-track {
    margin-left: 0;
    margin-right: 0;
  }
`

interface ListViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render a list of articles or media objects
 * @returns {JSX.Element} Component template
 */
const MosaicView: FC<ListViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage } = useContext(AppContext);
  const listRef = useRef<HTMLUListElement>(null);
  const [columnCount, setColumnCount] = useState<number>(3);
  const [rowCount, setRowCount] = useState<number>(2);
  const [gridGap, setGridGap] = useState<number>(20);
  const [titleColor, setTitleColor] = useState<string>("#ffffff");
  const [subtitleColor, setSubtitleColor] = useState<string>("#ffffff");
  const [titleSize, setTitleSize] = useState<string>("1.5em");
  const [subtitleSize, setSubtitleSize] = useState<string>("1em");
  const [textPosition, setTextPosition] = useState<string>("bottom-left");
  const [textBackground, setTextBackground] = useState<string>("shadow");
  const [cellBackgroundColor, setCellBackgroundColor] = useState<string>();
  const [gridFlow, setGridFlow] = useState<string>("row");
  const [data, error] = useFetchDocument(documentId, previewData);

  /**
   * Convert hex color code to rgb
   * @param {string} hex Hex color code
   * @param {number} opacity Opacity to append. Optional
   * @returns {string} Rgb color code
   */
  const hex2rgb = useCallback((hex: string, opacity?: number) => {
    if (typeof hex !== "string") { return }

    const r = parseInt(hex?.slice(1, 3), 16);
    const g = parseInt(hex?.slice(3, 5), 16);
    const b = parseInt(hex?.slice(5, 7), 16);
    
    return `rgb(${r} ${g} ${b} / ${opacity || 0}%)`;
  }, []);

  /**
   * Check if scrollbar should be visible
   */
  const checkScrollbarVisibility = useCallback(() => {
    setTimeout(() => {
      if (listRef.current) {
        listRef.current.classList.remove("snapScroll");
        if (listRef.current?.scrollWidth > listRef.current?.clientWidth) {
          listRef.current.classList.add("snapScroll");
        }
      }
    }, 100);
  }, []);

  // Get the grid size. Can be received as a string ("2x3") or an object ({rows: 2, columns: 3})
  useEffect(() => {
    if (typeof data?.content?.settings?.grid?.gridSize === "string") {
      const gridSize = data?.content?.settings?.grid?.gridSize;
      setColumnCount(Number(gridSize?.split("x")?.[0] || columnCount))
      setRowCount(Number(gridSize?.split("x")?.[1] || rowCount))
    } else {
      setRowCount(data?.content?.settings?.grid?.rows || rowCount);
      setColumnCount(data?.content?.settings?.grid?.columns || columnCount);
    }
      
    setCellBackgroundColor(hex2rgb(data?.content?.settings?.grid?.backgroundColor, data?.content?.settings?.grid?.backgroundOpacity))

    setGridFlow(data?.content?.settings?.grid?.flow || "row");

    checkScrollbarVisibility();
  }, [data?.content?.settings, hex2rgb, columnCount, rowCount, checkScrollbarVisibility]);

  // Update grid parameters: rows, columns and grid gap
  useEffect(() => {
    if (listRef.current) {
      listRef.current.style.gridTemplateRows = `repeat(${rowCount}, calc(${100/rowCount}% - ${(rowCount * gridGap/rowCount) - (gridGap/rowCount)}px))`;
      listRef.current.style.gridTemplateColumns = `repeat(${columnCount}, calc(${100/columnCount}% - ${(gridGap * (columnCount - 1)/columnCount)}px))`;
      listRef.current.style.gridAutoColumns = `minmax(${100/columnCount}%, 1fr)`;
      listRef.current.style.gap = `${gridGap}px`;
      listRef.current.style.gridAutoFlow = `${gridFlow} dense`;
    }
  }, [rowCount, columnCount, gridGap, gridFlow]);

  // Should the scrollbar be visible?
  useEffect(() => {
    detectDomChanges(listRef, () => {
      checkScrollbarVisibility();
    });
  }, [checkScrollbarVisibility]);

  // Set grid and text states to be used in the MosaicItem component
  useEffect(() => {
    setGridGap(data?.content?.settings?.grid?.gap || gridGap);
    setTitleColor(data?.content?.settings?.text?.titleColor || titleColor);
    setSubtitleColor(data?.content?.settings?.text?.subtitleColor || titleColor);
    setTitleSize(data?.content?.settings?.text?.titleSize || titleSize);
    setSubtitleSize(data?.content?.settings?.text?.subtitleSize || subtitleSize);
    setTextPosition(data?.content?.settings?.text?.textPosition || textPosition);
    setTextBackground(data?.content?.settings?.text?.textBackground || textBackground);
  }, [data, rowCount, columnCount, gridGap, titleColor, subtitleColor, titleSize, subtitleSize, textPosition, textBackground]);

  // Do layout adjustments based on items count if grid size is not specified
  useEffect(() => {
    let rows = 0, cols = 0;
    const itemsCount = data?.content?.records?.list?.length;
    const gridSettings: any = data?.content?.settings?.grid;

    // If no row counter is specified
    if (isNaN(gridSettings?.rows)) {
      if (itemsCount <= 3) {
        rows = 1;
      } else {
        rows = 2;
      }

      listRef.current && setRowCount(rows);
    }

    // If no column counter is specified
    if (isNaN(gridSettings?.columns)) {
      if (itemsCount <= 3) {
        cols = itemsCount;
      } else if (itemsCount === 4) {
        cols = 2;
      } else {
        cols = 3;
      }

      listRef.current && setColumnCount(cols);
    }
  }, [data, rowCount, columnCount]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) || (data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
        <BackgroundImage media={data?.content?.background?.image} backgroundColor={data?.content?.background?.backgroundColor} />
        <ListWrapper ref={listRef} className="mosaic">
          {data?.content?.records?.list?.map((item: any, i: number) => {
            return <ListItem key={`listìtem-${i}`} documentId={item?.to_document_id} image={item?.content?.image} title={item?.content?.title} subtitle={item?.content?.subtitle} cellSize={item?.content?.cellSize} textPosition={textPosition} titleColor={titleColor} subtitleColor={subtitleColor} titleSize={titleSize} subtitleSize={subtitleSize} textBackground={textBackground} cellBackgroundColor={cellBackgroundColor}/>
          })}
        </ListWrapper>
      </PageTransition>
    </>
  );
}

export default MosaicView;
