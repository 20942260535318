import { useContext, useEffect, useRef, useState } from "react";
import { fetchDMContent, fetchMedia } from "../services/Api";
import { DM_API_KEY, DM_DMS_AUDIO_URL, DM_DMS_IMAGE_URL, DM_DMS_VIDEO_URL, DM_OBJECT_URL } from "../Settings";
import { AppContext } from "../contexts/AppContext";
import { verifyEmsUrl } from "../common/Media";

/**
 * Fetch media object from the API
 * @param {object} media Media object
 * @param {string} options.dimension Dimension. Optional.
 * @param {boolean} options.includeOriginalUrl Should the original media URL be returned? Optional. Default: false
 * @returns {object} Media object
 */
export default function useFetchMedia(media: any, options?: { dimension?: string, includeOriginalUrl?: boolean }) {
  const isSubscribed = useRef<boolean>(false);
  const cache = useRef({});
  const { forceUseOriginalMedia } = useContext(AppContext);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    isSubscribed.current = true;
    (async() => {
      if (!media || media === "undefined" || Object.keys(media).length === 0 || cache?.current === JSON.stringify(media)) { return }

      setData(null);
      setIsLoading(true);
      cache.current = JSON.stringify(media);

      // Is media object a string?
      if (typeof media === "string") { 
        setData({url: media}); 
        return;
      }

      // Is a DMS id provided?
      if (media?.dmsId) {
        switch(media?.media_type) {
          case "audio":
            setData({...media, ...{url: DM_DMS_AUDIO_URL.replaceAll("{id}", media?.dmsId)}});
            break;
          case "video":
            setData({...media, ...{url: DM_DMS_VIDEO_URL.replaceAll("{id}", media?.dmsId), thumbUrl: DM_DMS_IMAGE_URL.replaceAll("{id}", media?.dmsId)}});
            break;
          default:
            setData({...media, ...{url: DM_DMS_IMAGE_URL.replaceAll("{id}", media?.dmsId)}});
        }
        return;
      }

      // Skip API request if media is already loaded
      if (media?.url) {
        setData(media);
        return;
      }

      if (!media?.mediaId) { return }

      fetchMedia(String(media?.mediaId), { includeOriginalUrl: options?.includeOriginalUrl || forceUseOriginalMedia} ).then(async mediaObject => {
        if (isSubscribed.current) {
          let url = {};
          switch (mediaObject?.media_type) {
            case "video":
            case "audio":
            case "model3d":
              let thumbOverrideUrl;
              if (mediaObject?.thumbnail_override?.mediaId) {
                thumbOverrideUrl = await fetchMedia(mediaObject?.thumbnail_override?.mediaId);
              }
              url = { url: verifyEmsUrl(mediaObject?.src), thumbUrl: verifyEmsUrl(thumbOverrideUrl?.src || mediaObject?.thumbnail_src || "") }
              break;
            case "image":
            case "dm":
            case "document":
              url = { url: `${verifyEmsUrl(mediaObject?.src)}${options?.dimension ? '?dimension=' + options.dimension : ''}`, thumbUrl: `${verifyEmsUrl(mediaObject?.thumbnail_src)}` }
              break;
            case "sketchfab":
            case "youtube":
            case "vimeo":
              url = { url: `${mediaObject?.src}`, thumbUrl: `${verifyEmsUrl(mediaObject?.thumbnail_src)}` }
              break;
          }

          // Should DM metadata also be fetched?
          let credits = mediaObject?.credits;
          if (mediaObject?.media_type === "dm" && mediaObject?.identifier) {
            await fetchDMContent(`${DM_OBJECT_URL.replace("{id}", mediaObject?.identifier)}&api.key=${DM_API_KEY}`).then(data => {
              let creditList: any[] = [];
              if (data?.producer) { creditList.push({credit_type_id: 1, name: data?.producer}) }
              if (data?.partOfCollection?.name) { creditList.push({credit_type_id: 6, name: data?.partOfCollection?.name}) }
              credits = { credits: creditList };
            });
          }

          if (isSubscribed.current) {
            setData({...mediaObject, ...url, ...credits, ...media});
            setIsLoading(false);
          }
        }
      }).catch(e => {
        setError(e); 
      });
    })();
    return () => { 
      isSubscribed.current = false; 
    }
  }, [media, data, options, forceUseOriginalMedia]);

  if (!data || (!media?.mediaId && !media?.url && !media?.dmsId)) {
    return [null, null, false];
  }

  return [data, error, isLoading]
}