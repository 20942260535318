import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getContentType, getIcon } from '../common/Common';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import Image from './Image';
import Link from './Link';
import useFetchDocument from '../hooks/useFetchDocument';
import useWindowSize from '../hooks/useWindowSize';

interface HomeScreenItemProps {
  documentId: number,
  icon?: any,
  title?: string,
  backgroundColor?: string
}

const Item = styled.li`
  float: left;
  overflow-y: visisble;
  padding: 0;
  margin: 20px ${p => p.theme.scaleFactor * 20}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:active {
    filter: brightness(0.8);
  }
`

const StyledImage = styled(Image).attrs((p: any) => ({
  size: p.size || 200,
}))`
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  display: block;
  position: relative;
  z-index: 1;
  box-shadow: ${p => p.theme.elementShadow};
  display: block;

  & img {
    border-radius: 50%;
    height: ${p => p.size}px;
    width: ${p => p.size}px;
  }
`

const ImagePlaceholder = styled.div.attrs((p: any) => ({
  size: p.size || 200,
  visible: p.visible || false
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${p => p.theme.textColor};
  box-shadow: ${p => p.theme.elementShadow};
  visibility: ${p => p.visible ? "visible" : "hidden"};

  & svg {
    fill: ${p => p.theme.backgroundColor};
    width: 25%;
    height: 20%;
  }
`

const Title = styled.span.attrs((p: any) => ({
  size: p.size || 200
}))`
  display: block;
  margin: 20px 0 0;
  height: 60px;
  width: ${p => p.size}px;
  color: ${p => p.theme.textColor};
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  text-shadow: 1px 1px 5px rgb(0 0 0 / 50%);

  &.light {
    color: #000;
    text-shadow: 1px 1px 5px rgb(255 255 255 / 30%);
  }
`

/**
 * Render an item for the home screen view
 * @returns {JSX.Element} Component template
 */
const HomeScreenItem: FC<HomeScreenItemProps> = ({documentId, icon, title, backgroundColor}) => {
  const { currentLanguage } = useContext(AppContext);
  const [contentType, setContentType] = useState<string>();
  const [itemSize, setItemSize] = useState<number>(0);
  const windowSize = useWindowSize();
  const [data] = useFetchDocument(documentId);
  const [colorMode, setColorMode] = useState<string>("dark");

  const determineContrast = useCallback((hexColor: string) => {
    if (!hexColor) { return }

    /**
     * Check background color to determine if dark or light mode should be used
     */
    let [r, g, b] = hexColor.match(/\w\w/g)!.map(x => parseInt(x, 16));
    if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
      setColorMode("light");
    } else {
      setColorMode("dark");
    }
  }, []);

  // Get content type of a presentation or article
  useEffect(() => {
    if (data?.schema_id) {
      setContentType(getContentType(data))
    }
  }, [data]);

  // Calculate size of items based on viewport
  useEffect(() => {
    setItemSize(windowSize?.width * 0.15);
  }, [setItemSize, windowSize]);

  // Check background color to determine if dark or light mode should be used
  useEffect(() => {
    if (backgroundColor) {
      determineContrast(backgroundColor);
    }
  }, [backgroundColor, determineContrast]);

  // Do not display link if not available in current language
  if (data?.status && !(data?.title?.[currentLanguage || "no"] || data?.content?.content?.blocks?.[currentLanguage || "no"])) { return null; }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <Item>
      <Link id={documentId} presentationType={contentType || ""}>
        <>
          {(contentType && icon?.mediaId) ? (<StyledImage media={icon} size={itemSize}/>) : (<ImagePlaceholder size={itemSize} visible={Boolean(contentType)}>{getIcon(contentType || "article")}</ImagePlaceholder>) }
          <Title size={itemSize} className={colorMode}>{(title && getTranslation(title, currentLanguage)?.length > 0) ? getTranslation(title, currentLanguage) : getTranslation(data?.title, currentLanguage)}</Title>
        </>
      </Link>
    </Item>
  );
}

export default HomeScreenItem;
