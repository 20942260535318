import { FC, useContext, useEffect, useState } from 'react';
import { getPresentationComponent } from '../common/Common';
import { AppContext } from '../contexts/AppContext';
import ArticleView from './Article';
import DeviceHandle from './Device';
import SplashScreen from './SplashScreen';
import { ARTICLE_SCHEMA_ID, DEVICE_SCHEMA_ID, PRESENTATION_SCHEMA_ID } from '../Settings';

/**
 * Render a preview based on incoming postMessage data
 * @returns {JSX.Element} Component template
 */
const PreviewView: FC = () => {
  const { setCurrentLanguage, setPreviewIsActive } = useContext(AppContext);
  const [previewData, setPreviewData] = useState<any>(null);
  const [formData, setFormData] = useState<any>({});
  const [schemaId, setSchemaId] = useState<number>();
  const [previewElement, setPreviewElement] = useState<JSX.Element>();

  // Update previewdata state when postmessage is sensed
  useEffect(() => {
    window.addEventListener("message", message => {
      setPreviewIsActive(true);

      try {
        setPreviewData(JSON.parse(message?.data));
      } catch (e) {}
    }, false);
  }, [setPreviewIsActive]);

  // Extract language, formData and schema name on each update
  useEffect(() => {
    // Get language
    setCurrentLanguage(previewData?.locale || "no");

    // Get formdata
    setFormData(previewData?.formData);

    // Get schema name
    setSchemaId(previewData?.schemas?.schema_id);
  }, [previewData, setCurrentLanguage]);  

  // Get the correct view based on document type and presentation type
  useEffect(() => {
    switch(schemaId) {
      case ARTICLE_SCHEMA_ID:
        setPreviewElement(<ArticleView previewData={formData}/>);
        break;
      case PRESENTATION_SCHEMA_ID:
        setPreviewElement(getPresentationComponent(formData?.general?.presentationType, formData));
        break;
      case DEVICE_SCHEMA_ID:
        setPreviewElement(<DeviceHandle previewData={formData}/>);
        break;
      default:
        setPreviewElement(<SplashScreen/>);
        break;
    }
  }, [schemaId, formData]);

  return <>{previewElement}</>;
}

export default PreviewView;
