import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';

interface ArticleTextProps {
  text: string
}

const HTMLElement = styled.div`
  font-weight: 400;
  line-height: 1.4em;
  font-size: 1.2em;

  & li {
    padding: 0 0 15px 0;
  }

  & h2 {
    line-height: ${p => p.theme.scaleFactor * 2.1}rem;
  }
`;

/**
 * Render a HTML/markdown text block
 * @returns {JSX.Element} Component template
 */
 const BlockText: FC<ArticleTextProps> = ({text}) => {
  const [parsedString, setParsedString] = useState<string>("");

  /**
   * Unescape html characters from string
   * @param {string} string String to parse
   * @returns {string} Parsed string
   */
   const unescapeHtml = useCallback((htmlString: string) => {
    if (typeof htmlString !== "string") { return ""; }
    return htmlString
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'");
  }, []);

  // Parse string as HTML or markdown
  useEffect(() => {
    if (typeof text !== "string") { return; }

      // HTML: Only include whitelisted tags
      const htmlStripRegex = /<(?!\/?(p|br|h1|h2|h3|h4|h5|h6|b|i|u|strong|em|strike|sub|sup|ul|ol|li|span)\b[\s]*[/]?)[^>]+>/gi;
      setParsedString(unescapeHtml(text).replace(htmlStripRegex, ""));
  }, [text, unescapeHtml]);

  return (
    <BlockContainer className="textblock"><HTMLElement dangerouslySetInnerHTML={{__html: parsedString}}/></BlockContainer>
  );
}

export default BlockText;