import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MarkerIcon } from "../assets/marker_classic.svg";
import { ReactComponent as CircleIcon } from "../assets/marker_circle.svg";
import { getContentType } from '../common/Common';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import ImageMapPopup from './ImageMapPopup';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchMedia from '../hooks/useFetchMedia';

interface ImageMapMarkerProps {
  documentId: number,
  title?: string,
  subtitle?: string,
  image?: any,
  markerPosition: { x: number, y: number },
  markerColor?: string,
  markerStyle?: string,
  callback?: () => void
}

const Marker = styled.div.attrs((p: any) => ({
  markerColor: p.markerColor || "#ffffff",
  zoom: p.zoom || undefined
}))`
  position: absolute;
  transform-origin: 22px 60px;
  transform: scale(0) translate3d(0, 0, 0);
  zoom: ${p => p.zoom};
  scale: ${p => p.theme.scaleFactor * .9};
  z-index: 10;
  cursor: pointer;
  display: inline;

  &.mapmarker {
    transform-origin: 22px 60px;
  }

  &.circle {
    transform-origin: 20px 20px;
  }

  &:active {
    filter: brightness(0.8);
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    z-index: 13;
  }

  & svg {
  }

  & svg path, & svg circle {
    fill: ${p => p.markerColor};
    filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4));
  }
`

/**
 * Render a marker for the imagemap view
 * @returns {JSX.Element} Component template
 */
const ImageMapMarker: FC<ImageMapMarkerProps> = ({documentId, title, subtitle, image, markerPosition, markerColor, markerStyle = "mapmarker", callback}) => {  
  const { currentLanguage } = useContext(AppContext);
  const [presentationTitle, setPresentationTitle] = useState<any>(null);
  const [presentationSubtitle, setPresentationSubtitle] = useState<any>(null);
  const [contentType, setContentType] = useState<string>("");
  const [clickPosition, setClickPosition] = useState<any>(null);
  const [data] = useFetchDocument(documentId);
  const [presentationMedia] = useFetchMedia(image?.mediaId ? image : (data?.content?.general?.image || data?.content?.background?.image), { dimension: "600x600" });

  // Get content type of a presentation or article
  useEffect(() => {
    setContentType(getContentType(data))
  }, [data]);

  // Get title and subtitle
  useEffect(() => {
    if (data) {
      setPresentationTitle(getTranslation(title, currentLanguage)?.length > 0 ? getTranslation(title, currentLanguage) : getTranslation(data?.title, currentLanguage));
      setPresentationSubtitle(getTranslation(subtitle, currentLanguage));
    }
  }, [title, subtitle, currentLanguage, data]);

  // Hide all other popups and show the current one
  const showPopup = useCallback((e: any) => {
    // Hide all other popups
    const popups = document.querySelectorAll(`.imagemappopup`);
    popups?.forEach(element => {
      element.classList.remove("visible");
    });

    // Make sure all other markers are behind the current popup
    const markers = document.querySelectorAll(`.imagemapelement`);
    markers?.forEach(element => {
      element.classList.remove("active");
    });

    e.target.classList.add("active");
    e.target.querySelector("div.imagemappopup")?.classList?.add("visible");

    setClickPosition({
      x: e?.clientX,
      y: e?.clientY
    });
  }, []);

  // Run callback function when data is loaded
  useEffect(() => {
    if (callback && data?.content) {
      callback();
    }
  }, [callback, data?.content]);

  // Do not display link if not available in current language
  if (data?.status && !(data?.title?.[currentLanguage || "no"] || data?.content?.content?.blocks?.[currentLanguage || "no"])) { return null; }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <Marker className={`imagemapelement ${markerStyle || "mapmarker"}`} style={{left: `calc(${markerPosition.x}% - ${markerStyle === "mapmarker" ? 22 : 20}px)`, top: `calc(${markerPosition.y}% - ${markerStyle === "mapmarker" ? 60 : 22}px)`}} markerColor={markerColor} onClick={showPopup}>
      {markerStyle === "mapmarker" && (<MarkerIcon/>)}
      {markerStyle === "circle" && (<CircleIcon/>)}
      <ImageMapPopup documentId={documentId} title={presentationTitle} image={presentationMedia} position={clickPosition} contentType={contentType} markerColor={markerColor} subtitle={presentationSubtitle}/>
    </Marker>
  );
}

export default ImageMapMarker;
