import { FC } from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';
import BlockContainer from './BlockContainer';

interface BlockLinkItemProps {
  url: string,
  title?: string
}

interface BlockLinksWrapperProps {
  children: JSX.Element | JSX.Element[]
}

const BlockLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BlockLink = styled.li`
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & svg {
    width: 150px;
    height: 150px;
  }
`;

const Title = styled.span`
  font-weight: bold;
  padding: 10px 25px;
`;

/**
 * Render a PDF block container
 * @returns {JSX.Element} Component template
 */
export const BlockLinksWrapper: FC<BlockLinksWrapperProps> = ({children}) => {
  return (
    <BlockContainer>
      <BlockLinks>
        {children}
      </BlockLinks>
    </BlockContainer>
  )
}

/**
 * Render a link block
 * @returns {JSX.Element} Component template
 */
 export const BlockLinkItem: FC<BlockLinkItemProps> = ({url, title}) => {

  if (!url || url.length < 3) {
    return null;
  }

  return (
    <BlockLink>
      <QRCode value={url || ""} />
      {title && (<Title>{title}</Title>)}
    </BlockLink>
  );
}