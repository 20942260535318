import { FC } from 'react';
import styled from 'styled-components';
import QuoteIcon from "../assets/icon_quote.svg";
import BlockContainer from './BlockContainer';

interface BlockQuoteProps {
  quote?: string,
  byline?: string
}

const Wrapper = styled.div`
  float: left;
  width: 100%;
  clear: both;
  margin: 20px 0;
`;

const Quote = styled.span`
  background-image: url(${QuoteIcon});
  background-repeat: no-repeat;
  background-position: 0px 5px;
  padding-left: 45px;
  min-height: 35px;
  display: block;
  font-style: italic;
  font-size: 1.2em;
`;

const Byline = styled.span`
  margin-top: 10px;
  float: right;
`;

/**
 * Render a quote block
 * @returns {JSX.Element} Component template
 */
const BlockQuote: FC<BlockQuoteProps> = ({quote, byline}) => {

  return (
    <BlockContainer>
      <Wrapper>
        <Quote>{quote}</Quote>
        {byline && byline.length > 0 && (<Byline>– {byline}</Byline>)}
      </Wrapper>
    </BlockContainer>
  );
}

export default BlockQuote;