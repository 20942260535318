import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #1c1a1a url("./img/splashbackground.jpg") no-repeat 50% 50%; 
  background-size: cover; 
  position: absolute; 
  top: 0; 
  bottom: 0; 
  left: 0; 
  right: 0;
  display: flex; 
  align-items: center; 
  justify-content: center;
`

const Container = styled.section`
  width: 540px;
  text-align: center;
  padding: 50px 0 120px;
  overflow: hidden;
  position: relative; 
  border-radius: 5px;
  display: flex;
  align-items: center;
` 

const Simulator = styled.iframe`
  border: 10px solid #333;
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  transform-origin: 0 0;
  border-radius: 3px;
`

const InputField = styled.input.attrs({ type: 'number' })`
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 1.5em;
  outline: none;
  max-width: 220px;
  margin: 5px;
`;

const SubmitButton = styled.button`
  background-color: ${p => p.theme.accentColor};
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 1.5em;
  outline: none;
  margin: 5px;
  cursor: pointer;
`;

const Form = styled.form`
  margin: 0 auto;
  text-align: center;
`

const Text = styled.p`
  color: #000;
  margin: 0 0 10px;
`;

const ResetButton = styled.span`
  display: block;
  cursor: pointer;
  position: absolute;
  bottom: 50px;
`;

/**
 * Render a simulator
 * @returns {JSX.Element} Component template
 */
const SimulatorView: FC = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams<{deviceId: string}>();
  const simulatorRef = useRef<HTMLIFrameElement>(null);
  const deviceInputRef = useRef<HTMLInputElement>(null);
  const [deviceUrl, setDeviceUrl] = useState<string>("/");

  // Is the current browser Internet Explorer?
  const isIE = useCallback(() => {
    let isIE = false;
    const ua = window.navigator.userAgent;
    const old_ie = ua.indexOf('MSIE ');
    const new_ie = ua.indexOf('Trident/');

    if ((old_ie > -1) || (new_ie > -1)) {
      isIE = true;
    }

    return isIE;
  }, []);

  // Resize iframe based on available with and height
  const scaleIframe = useCallback((width, height) => {
    let scale: Number;
    
    // Calculate scale
    if (width > height) {
      scale = Math.min((document.body.clientWidth - 500) / width);
    } else {
      scale = Math.min((document.body.clientHeight) / height);
    }
    
    // Scale iframe
    if (simulatorRef.current) {
      simulatorRef.current.style.transform = "scale(" + scale + ") translate(-50%, -50%)";
      simulatorRef.current.width = width;
      simulatorRef.current.height = height;
    }
  }, []);

  // Resize iframe on startup and on browser resize
  useEffect(() => {
    scaleIframe(1920, 1080);

    // Update dimensions on resize
    window.addEventListener("resize", () => {
      scaleIframe(1920, 1080);
    });
  }, [scaleIframe]);

  // Get the device URL
  useEffect(() => {
    if (!deviceId || deviceId === "") { return; }
    setDeviceUrl(`/#/device/${deviceId}`);
    scaleIframe(1920, 1080);
  }, [deviceId, scaleIframe]);

  /**
   * Navigate to simulator on deviceId input
   */
  const updateDeviceId = useCallback((e) => {
    e.preventDefault();
    if (deviceInputRef.current) {
      navigate(`/simulator/${deviceInputRef.current.value}`);
    }
    return false;
  }, [navigate]);

  const resetSimulator = useCallback(() => {
    navigate(`/simulator/`);
  }, [navigate]);

  if (isIE()) {
    return (
      <Wrapper>
        <Text>Internet Explorer er ikke støttet. Vennligst benytt Google Chrome, Safari eller andre nyere nettlesere.</Text>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {deviceId ? (
        <>
          <Simulator src={deviceUrl} ref={simulatorRef}/>
          <ResetButton onClick={resetSimulator}>Nullstill</ResetButton>
        </>
      ) : (
        <Container>
          <Form method="GET" onSubmit={updateDeviceId}>
            <Text>Skriv inn IDen til enheten du ønsker å forhåndsvise</Text>
            <InputField placeholder='ID' ref={deviceInputRef}/>
            <SubmitButton>Vis</SubmitButton>
          </Form>
        </Container>
      )}
    </Wrapper>
  );
}

export default SimulatorView;
