import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import TimelineCard from '../components/TimelineItem';
import { generateCSSFilters, getImageUrl, showImageWhenLoaded } from '../common/Media';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import ErrorMessageView from '../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchMedia from '../hooks/useFetchMedia';
import { MEDIA_PREFIX } from '../Settings';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

const TimelineWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${p => p.theme.scaleFactor * 100}px ${p => p.theme.scaleFactor * 50}px 0;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateZ(0);

  & li:first-child {
    margin-left: -30px;;
  }

  &::-webkit-scrollbar {
    height: 80px;
  }

  &::-webkit-scrollbar-thumb {
    border-bottom: 50px solid transparent;
    border-top: 20px solid transparent;
    background-color: ${p => p.theme.accentColor};
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    margin-left: ${p => p.theme.scaleFactor * 80}px;
    margin-right: ${p => p.theme.scaleFactor * 80}px;
  }
`

const ParallaxImage = styled.img.attrs((props: any) => ({
  focalX: props.focalX || "50",
  focalY: props.focalY || "50",
  filters: props.filters
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  object-position: ${props => props.focalX}% ${props => props.focalY}%;
  filter: ${props => props.filters};
  perspective: 1000;
  backface-visibility: hidden;
`

const StaticBackground = styled.div.attrs((p: any) => ({
  backgroundColor: p.backgroundColor || null
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateY(0);
  ${p => p.backgroundColor && `background-color: ${p.backgroundColor}`}
`;

interface TimelineProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render the timeline view
 * @returns {JSX.Element} Component template
 */
const TimelineView: FC<TimelineProps> = ({previewData=null, overrideTitle}) => {
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage } = useContext(AppContext);
  const { t } = useTranslation();
  const parallaxImage1Element = useRef<HTMLImageElement>(null);
  const parallaxImage2Element = useRef<HTMLImageElement>(null);
  const [data, error] = useFetchDocument(documentId, previewData);
  const [parallaxImage1] = useFetchMedia(data?.content?.background?.image, { dimension: "max" });
  const [parallaxImage2] = useFetchMedia(data?.content?.background?.parallaxImage, { dimension: "max" });
  const [parallaxFilterCss1, setParallaxFilterCss1] = useState<any>();
  const [parallaxFilterCss2, setParallaxFilterCss2] = useState<any>();

  /**
   * Make parallax scroll effect on background images
   * @param e Event
   */
  const parallaxScroll = useCallback((e: any) => {
    const percentage = e.target.scrollLeft / (e.target.scrollWidth - e.target.offsetWidth) * 100;

    if (parallaxImage1Element.current) {
      parallaxImage1Element.current.style.transform = `translateX(-${percentage/3}%) translateZ(0)`;
    }

    if (parallaxImage2Element.current) {
      parallaxImage2Element.current.style.transform = `translateX(-${percentage/2}%) translateZ(0)`;
    }
  }, []);

  // Parse CSS filters from media object
  useEffect(() => {
    if (parallaxImage1?.filters) {
      setParallaxFilterCss1(generateCSSFilters(parallaxImage1?.filters));
    }
  }, [parallaxImage1]);

  // Parse CSS filters from media object
  useEffect(() => {
    if (parallaxImage2?.filters) {
      setParallaxFilterCss2(generateCSSFilters(parallaxImage2?.filters));
    }
  }, [parallaxImage2]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}/> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) || (data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
        <Wrapper>
          {data?.content?.background?.backgroundColor && (<StaticBackground backgroundColor={data?.content?.background?.backgroundColor}/>)}
          {parallaxImage1?.url && (<ParallaxImage ref={parallaxImage1Element} src={getImageUrl(`${MEDIA_PREFIX}${parallaxImage1?.url}`)} style={{width: "150%"}} onLoad={showImageWhenLoaded} className="loading"  focalX={data?.content?.background?.image?.focalPoint?.x} focalY={data?.content?.background?.image?.focalPoint?.y} filters={parallaxFilterCss1}/>)}
          {parallaxImage2?.url && (<ParallaxImage ref={parallaxImage2Element} src={getImageUrl(`${MEDIA_PREFIX}${parallaxImage2?.url}`, { transparency: true })} style={{width: "200%"}} onLoad={showImageWhenLoaded} className="loading" focalX={data?.content?.background?.parallaxImage?.focalPoint?.x} focalY={data?.content?.background?.parallaxImage?.focalPoint?.y} filters={parallaxFilterCss2}/>)}
          <TimelineWrapper className="scroll" onScroll={e => parallaxScroll(e)}>
            {data?.content?.records?.list?.map((item, i) => {
              return <TimelineCard key={`timelineitem-${i}`} documentId={item.to_document_id} image={item?.content?.image} title={item?.content?.title} subtitle={item?.content?.subtitle} display={data?.content?.settings?.display?.elements} textBackground={data?.content?.settings?.text?.textBackground}/>
            })}
          </TimelineWrapper>
        </Wrapper>
      </PageTransition>
    </>
  );
}

export default TimelineView;
