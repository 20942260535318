import { FC, useEffect, useState } from 'react';
import { BlockMediaItem, BlockMediaWrapper } from './BlockMedia';
import { fetchDMContent } from '../services/Api';
import { DM_DMS_IMAGE_URL, DM_SEARCH_URL, DM_API_KEY } from '../Settings';

interface BlockDMProps {
  search?: any
}

/**
 * Render a DigitaltMuseum search block
 * @returns {JSX.Element} Component template
 */
const BlockDMSearch: FC<BlockDMProps> = ({search}) => {
  const [dmSearchQuery, setDmSearchQuery] = useState<string>("");
  const [dmSearchResults, setDmSearchResults] = useState<any>();

  // Get search data from DM
  useEffect(() => {
    let isSubscribed = true;

    if (dmSearchQuery.length === 0) { return; }
      fetchDMContent(`${dmSearchQuery}`).then(data => {
        isSubscribed && setDmSearchResults(data?.response);
    });

    return () => { isSubscribed = false };
  }, [dmSearchQuery]);

  // Generate DM search URL
  useEffect(() => {
    if (!search) { return; }
    let searchString = "&fq=artifact.hasPictures:true";

    // Freetext
    if (search?.query?.length > 1) {
      searchString += `&q=${search?.query}`;
    } else {
      searchString += `&q=*`;
    }

    // Owner
    if (search?.ownerCode?.length > 1) {
      searchString += `&fq=identifier.owner:${search?.ownerCode}`;
    }

    // Object type
    if (search?.objectType?.length > 1) {
      switch (search?.objectType) {
        case "VM":
          searchString += `&fq=(artifact.subtype:vm-exhibition)`;
          break;
        case "School":
          searchString += `&fq=(artifact.subtype:school_digital OR artifact.subtype:school_physical)`;
          break;
        default:
          searchString += `&fq=artifact.type:${search?.objectType}`;
      }
    }

    // Tags
    if (search?.tags?.length > 1) {
      let tagList = search?.tags?.split(",") || [];
      tagList?.forEach((tag: string) => {
        searchString += `&fq=artifact.ingress.subjects:${tag.trim()}`;
      });
    }

    // License
    if (search?.license?.length > 1) {
      searchString += `&fq=artifact.license:"${search?.license}"`;
    }

    // Sort
    if (search?.sort?.length > 1) {
      searchString += `&sort=artifact.${search?.sort} DESC`;
    }

    // Count
    if (search?.resultCount) {
      searchString += `&rows=${search?.resultCount}`;
    }

    setDmSearchQuery(`${DM_SEARCH_URL}select?wt=json&api.key=${DM_API_KEY}${searchString}`);
  }, [search]);

  return (
    <BlockMediaWrapper layout={"thumbs"} lightbox={true}>
      {dmSearchResults?.docs?.map((item: any, i: number) => {
        const mediaObject = {url: DM_DMS_IMAGE_URL.replace("{id}", item?.["artifact.defaultMediaIdentifier"]), media_type: "dm"}
        return <BlockMediaItem key={`item${i}`} media={mediaObject} lightbox={true} caption={item?.["artifact.ingress.title"]} credits=""/>
      })}    
    </BlockMediaWrapper>
  );

}

export default BlockDMSearch;