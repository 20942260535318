import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import Image from './Image';
import { parseCredits } from '../common/Media';

interface HeroImageProps {
  media: any,
  setBackgroundColor?: (color: string) => void
}

const Container = styled.div`
  mask-image: linear-gradient(180deg,rgba(0,0,0,.7),rgba(0,0,0,.7) 50%,rgba(0,0,0,.2) 75%,rgba(0,0,0,.05) 85%,transparent 100%);
`;

const MainImage = styled(Image)`
  width: 100%;
  max-width: 100%; // ${p => p.theme.blockWidth};
  height: ${p => p.theme.scaleFactor * 600}px;
  object-fit: cover;
  margin-bottom: -${p => p.theme.scaleFactor * 330}px;
`

const Credit = styled.span`
  display: block;
  color: #fff;
  transform: rotate(90deg) translate(100%, 0);
  transform-origin: 100% 0px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 1vh 2vh;
  font-size: .8em;
  max-width: 500px;
  text-shadow: 1px 1px 2px rgba(0,0,0,.6);
`;

/**
 * Render an article hero element
 * @returns {JSX.Element} Component template
 */
 const HeroImage: FC<HeroImageProps> = ({media}) => {
  const [mediaObject, setMediaObject] = useState<any>();



  /**
   * Scroll to article content when user clicks hero element
   */
  const scrollToContent = useCallback(() => {
    document?.getElementById(`article`)?.scrollIntoView();
  }, []);

  return (
    <Container>
      <MainImage media={media} onClick={scrollToContent} setMedia={setMediaObject}/>
      {mediaObject?.credits && (<Credit>{parseCredits(mediaObject?.credits)}</Credit>)}
    </Container>
  );
}

export default HeroImage;