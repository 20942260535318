import { FC, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import '@google/model-viewer/dist/model-viewer.min.js';
import { MEDIA_PREFIX } from '../Settings';

interface Model3dPopupProps {
  item: any,
  close?: () => void,
  caption?: string
}

interface ModelViewerJSX {
  src: string;
  poster?: string;
  iosSrc?: string;
  seamlessPoster?: boolean;
  autoplay?: boolean;
  environmentImage?: string;
  loading?: string,
  exposure?: string;
  interactionPromptThreshold?: string;
  shadowIntensity?: string;
  ar?: boolean;
  arModes?: string;
  autoRotate?: boolean;
  cameraControls?: boolean;
  cameraOrbit?: string;
  alt?: string;
  sx?: any;
  load?: (e: any) => void
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": ModelViewerJSX &
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: all;

  & model-viewer { 
    width: 100%;
    height: 100%;
  }

  &model-viewer[ar-tracking="not-presenting"] {
    background-color: red;
  }
`
const progressAnimation = keyframes`
  from { left: -30%; }
  to { left: 100%; }
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 6px;

  &.desktop {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &::before {
    position: absolute;
    content: "";
    width: 30%;
    height: 4px;
    background-color: ${p => p.theme.accentColor};
    border-radius: 2px;
    animation: ${progressAnimation} 1s infinite;
  }
`

const CaptionWrapper = styled.div`
  position: absolute;
  left: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, .8);
  max-height: 150px;
  overflow: hidden;
  

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%);
    width: 100%;
    height: 4em;
  }
`

const Caption = styled.div`
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 20px;
  width: 100%;
  overflow: auto;
` 

/**
 * Render a 3d popup based on model-viewer.dev
 * @returns {JSX.Element} Component template
 */
const Model3dPopup: FC<Model3dPopupProps> = ({item, close, caption}) => {
  const modelRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      // Dirty method to disable left and right swipe on the lightbox component
      const photoSwipeEl = document.querySelector(".pswp");
      photoSwipeEl && photoSwipeEl.classList.add("no_swipe");

      // Check if the model is loaded
      if (modelRef.current) {
        modelRef.current.addEventListener('progress', (event) => {
          if (event.detail.totalProgress < 1) {
            setIsLoading(true);
          } else {
            setIsLoading(false);
          }
        });
      }
    }, 500);
  }, []);

  return (
    <>
      {(item?.src || item?.url) && (
        <>
          <Container>
              {isLoading && (<ProgressBar role="progressbar" className="progress"/>)}
              <model-viewer ref={modelRef} alt="" src={`${MEDIA_PREFIX}${item?.src || item?.url}`} loading="eager" touch-action="none" shadow-intensity="1" camera-controls/>
          </Container>
          {caption && (<CaptionWrapper><Caption>{caption}</Caption></CaptionWrapper>)}
        </>
      )}
    </>
  )
}

export default Model3dPopup;