import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

interface PageProps {
  waitFor?: any,
  children?: JSX.Element|JSX.Element[],
  scroll?: boolean,
  height?: number
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Page = styled.div.attrs((p: any) => ({
  scroll: (typeof p.scroll !== "undefined" || Boolean(p.scroll)) ? true : false,
  visible: p.visible ? fadeIn : null,
  height: p.height || null
}))`
  width: 100%;
  min-height: 100%;
  float: left;
  animation: ${(p => p.visible) && fadeIn} 1.5s ease-in forwards;
  padding-bottom: ${p => p.scroll ? "50px" : 0};
  transform: translate3d(0, 0, 0);
  height: ${p => p.height ? p.height : "unset"};
`

/**
 * Render a page transition on enterence
 * @returns {JSX.Element} Component template
 */
const PageTransition: FC<PageProps> = ({waitFor, children, scroll, height}) => {
  const { pathname } = useLocation();

  // Set if current page can be scrollable
  useEffect(() => {
    if (scroll) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }

    // Scroll to top of page
    window?.scrollTo(0, 0);
  }, [scroll])

  // Scroll to top every time the URL changes
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Page visible={waitFor} scroll={scroll} height={height}>
      {children}
    </Page>
  );
}

export default PageTransition;