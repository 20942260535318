import { FC, useContext, useEffect } from "react"
import { Link as ReactLink } from 'react-router-dom';
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import Kulturio from '@ekultur/kulturio-bridge';

interface LinkProps {
  id?: number|string,
  presentationType: string,
  media?: any,
  mediaType?: string,
  children: JSX.Element,
  style?: any,
  className?: any,
  onClick?: (e?: any) => void
}

const BlockLink = styled(ReactLink)`
  position: relative;
  text-decoration: none;
`

const DummyLink = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

/**
 * Create internal link
 * @returns {JSX.Element} Component template
 */
 const Link: FC<LinkProps> = ({id, media, presentationType, children, style, className, onClick}) => {
  const {deviceId, setDeviceId, previewIsActive} = useContext(AppContext);

  // Get device ID from URL in case the app context is not updated
  useEffect(() => {
    if (!deviceId) {
      const idFromUrl = window?.location?.href?.match(/device\/(\d+)/);
      if (idFromUrl && idFromUrl[1]) {
        setDeviceId(idFromUrl[1]);
      }
    }
  }, [deviceId, setDeviceId]);

  // Track all link navigations as page visits
  useEffect(() => {
    Kulturio.addStatistics("pageVisits", 1, id);
  }, [id]);

  // Disable all links if preview is active
  if (previewIsActive) {
    return <DummyLink className="link">{children}</DummyLink>
  }

  // Return links based on presentation type
  switch (presentationType) {
    case "home":
      return <BlockLink to={`/`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "article":
      return <BlockLink to={`/device/${deviceId}/article/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "single":
      return <BlockLink to={`/device/${deviceId}/single/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "list":
      return <BlockLink to={`/device/${deviceId}/list/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "gallery":
      return <BlockLink to={`/device/${deviceId}/gallery/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "mosaic":
      return <BlockLink to={`/device/${deviceId}/mosaic/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "timeline":
      return <BlockLink to={`/device/${deviceId}/timeline/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "imagemap":
      return <BlockLink to={`/device/${deviceId}/imagemap/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "autoplay":
      return <BlockLink to={`/device/${deviceId}/autoplay/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "minnen":
      return <BlockLink to={`/device/${deviceId}/minnen/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    case "minner":
      return <BlockLink to={`/device/${deviceId}/minner/${id}`} style={style} className={className} onClick={onClick}>{children}</BlockLink>
    default:
      return children;
  } 
}

export default Link;