import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { getTranslation } from '../common/Translation';
import BlockContainer from './BlockContainer';
import Image from './Image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LightboxItem, LightboxWrapper } from './LightboxModal';
import useFetchMedia from '../hooks/useFetchMedia';
import { parseCredits } from '../common/Media';
import PlayIconSvg from "../assets/icon_play.svg";
import PrevIconSvg from "../assets/icon_prev_white.svg";
import NextIconSvg from "../assets/icon_next_white.svg";

interface BlockImageSlideshowProps {
  elements?: any
}

interface BlockImageSlideProps {
  element: any,
  isActive?: boolean,
  setCaption?: any,
  setDescription?: any,
  setCredits?: any
}

const BlockContain = styled(BlockContainer)`
  flex-direction: column;
`

const ImageItem = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  & figure {
    width: 100%;
    margin: 0 auto;
    height: 40vw;

    & img { 
      width: 100%; 
      max-height: 75vh;
      min-height: 20vh;
      object-fit: contain;
      transition: min-height .2s;
      background-color: transparent;
    }
  }

  &.placeholder figure {
    background: ${p => p.theme.placeholderColor} !important;
  }

  &.video figure::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: ${p => p.theme.accentColor};
    background-image: url(${PlayIconSvg});
    background-position: 60% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 10;
  }
`;

const ImageWrapper = styled.div`
  margin: 0 auto;
  width: fit-content;
`;

const ImageCaption = styled.span`
  font-size: .95em;
  display: block;
  margin-top: 8px;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  float: left;
  clear: both;
  word-break: break-word;
`;

const ImageCredits = styled.span`
  font-size: .75em;
  display: block;
  margin-top: 5px;
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  float: left;
  clear: both;
  word-break: break-word;
`;

const SliderButton = styled.button`
  position: absolute;
  top: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  border: 0;
  background-color: rgba(0, 0, 0, .3);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform: translateY(-50%);

  &::before {
    content: "";
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  &.prev {
    background-image: url(${PrevIconSvg});
    left: 15px;
  }

  &.next {
    background-image: url(${NextIconSvg});
    right: 15px;
  }
`;

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const BlockImageSlideshow: FC<BlockImageSlideshowProps> = ({elements}) => {
  const [credits, setCredits] = useState<string>("");
  const [caption, setCaption] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  return (
    <BlockContain>
      <LightboxWrapper>
        <Carousel className="showInPreview" 
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        emulateTouch={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (<SliderButton className="prev" onClick={onClickHandler} title={label}/>)
        }
        renderArrowNext={(onClickHandler, hasPrev, label) =>
          hasPrev && (<SliderButton className="next" onClick={onClickHandler} title={label}/>)
        }
        onChange={(index) => setCurrentSlideIndex(index)}>
          {elements.map((item: any, i: number) => {
            return <BlockImageSlide key={`element${i}`} element={item} isActive={Boolean(currentSlideIndex === i)} setCaption={setCaption} setDescription={setDescription} setCredits={setCredits} />
          })}
        </Carousel>
      </LightboxWrapper>
      <ImageCaption>
        {(caption && caption?.length > 0) && (<strong>{caption}</strong>)}
        {(caption && caption?.length > 0 && description && description?.length > 0) && (<> – </>)}
        {description}
      </ImageCaption>
      <ImageCredits>{credits}</ImageCredits>
    </BlockContain>
  );
}

const BlockImageSlide: FC<BlockImageSlideProps> = ({element, isActive, setCaption, setDescription, setCredits}) => {
  const { currentLanguage } = useContext(AppContext);
  const [itemMedia] = useFetchMedia(element);

  // Send credit and caption to parent if current slide is active
  useEffect(() => {
    if (isActive && itemMedia?.url) {
      setCaption(getTranslation(itemMedia?.title, currentLanguage));
      setDescription(getTranslation(itemMedia?.description, currentLanguage));
      setCredits(parseCredits(itemMedia?.credits));
    }
  }, [isActive, itemMedia, setCaption, setDescription, setCredits, currentLanguage]);

  //if (["youtube", "vimeo"].includes(itemMedia?.media_type)) { return null }

  if (!itemMedia?.url) { return null; }

  return (
    <ImageItem className={`${itemMedia?.media_type}`}>
      <LightboxItem media={itemMedia}>
        <ImageWrapper>
          <Image className="loading link" media={itemMedia} dimensions="3500"/>
        </ImageWrapper>
      </LightboxItem>
    </ImageItem>
  )
}

export default BlockImageSlideshow;