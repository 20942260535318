import { FC } from 'react';
import styled from 'styled-components';
import WarningIcon from '../assets/icon_warning.svg';
import BlockContainer from './BlockContainer';

interface BlockErrorProps {
  text: string,
  includeWrapper?: boolean
}

const Wrapper = styled.div`
  margin: 0 0 30px;
  padding: 20px 0 20px 70px;
  float: left;
  width: calc(100% - 50px);
  width: 100%;
  display: block;
  color: #fff;
  background-color: #ff3333;
  background-image: url(${WarningIcon});
  background-position: 30px 50%;
  background-repeat: no-repeat;
  border-radius: 5px;

  & p {
    margin: 0;
  }
`;

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const BlockError: FC<BlockErrorProps> = ({text, includeWrapper}) => {  
  if (includeWrapper === false) {
    return <Wrapper>{text}</Wrapper>
  } else {
    return (
      <BlockContainer>
        <Wrapper>{text}</Wrapper>
      </BlockContainer>
    );
  }
}

export default BlockError;