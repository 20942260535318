import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import { showImageWhenLoaded } from '../common/Media';
import { getTranslation } from '../common/Translation';
import ImageMapMarker from '../components/ImageMapMarker';
import ImageMapAnnotation from '../components/ImageMapAnnotation';
import ImageMapClickZone from '../components/ImageMapClickZone';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import PageTransition from '../components/PageTransition';
import ErrorMessageView from '../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import useFetchDocument from '../hooks/useFetchDocument';
import useFetchMedia from '../hooks/useFetchMedia';
import { MEDIA_PREFIX } from '../Settings';

const BackgroundImage = styled.img`
`

const MarkerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0%;
  transform: translate3d(0, 0, 0);
  filter: none;
`

const StaticBackground = styled.div.attrs((p: any) => ({
  backgroundColor: p.backgroundColor || null
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateY(0);
  ${p => p.backgroundColor && `background-color: ${p.backgroundColor}`}
`;

interface ImageMapViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render the image map
 * @returns {JSX.Element} Component template
 */
const ImageMapView: FC<ImageMapViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef<ReactZoomPanPinchRef>(null);
  const markersRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage, previewIsActive } = useContext(AppContext);
  const [minScale, setMinScale] = useState(1);
  const [data, error] = useFetchDocument(documentId, previewData);
  const [backgroundImage] = useFetchMedia(data?.content?.background?.image, { dimension: "max" });

  /**
   * Resize markers on scale on transformation
   * @param ref Reference to ReactZoomPanPinch object
   */
  const calibrateMarkers = useCallback((ref: ReactZoomPanPinchRef, previewIsActive?: boolean|undefined) => {
    const markers = document.querySelectorAll<HTMLElement>(".imagemapelement");
    let scale = ref?.state?.scale > 1 ? ref.state.scale : 1;

    if (previewIsActive) {
      scale = 1920/window.innerWidth;
    }

    markers?.forEach(marker => {
      marker.style.transform = `scale(${1/scale}) translate3d(0, 0, 0)`;
    });
  }, []);

  // Set image map size to viewport size
  const updateMarkerViewport = useCallback(() => {
    if (wrapperRef.current) {
      calibrateMarkers(wrapperRef.current);
    }
  }, [calibrateMarkers]);

  const resetPan = useCallback(() => {
    if (wrapperRef.current && imageRef.current) {
      wrapperRef.current.setTransform(
        -(imageRef.current.clientWidth - window.innerWidth) * (data?.content?.background?.image?.focalPoint?.x || 50) / 100, 
        -(imageRef.current.clientHeight - window.innerHeight) * (data?.content?.background?.image?.focalPoint?.y || 50) / 100, 1, 0);
      wrapperRef.current.zoomIn(0, 0);

      calibrateMarkers(wrapperRef.current);
    }
  }, [data, calibrateMarkers]);

  // Get image bounds
  const getImageBounds = useCallback(() => {
    if (imageRef.current) {
      let width = imageRef.current.naturalWidth;
      let height = imageRef.current.naturalHeight;

      const imageRatio = width/height;
      const windowRatio = window.innerWidth/window.innerHeight;

      if (imageRatio > windowRatio) {
        imageRef.current.style.height = `${window.innerHeight}px`;
        imageRef.current.style.width = `unset`;
      } else {
        imageRef.current.style.width = `${window.innerWidth}px`;
        imageRef.current.style.height = `unset`;
      }
      
      resetPan();
    }
  }, [resetPan]);

  // Resize if window size changes
  useEffect(() => {
    window.addEventListener('resize', () => {
      getImageBounds();
    });
  }, [getImageBounds]);

  /**
   * Hide all popups
   * @param e Event
   */
  const hideAllPopups = useCallback((e: any) => {
    if (e.nativeEvent.target.classList.value.includes('imagemapelement')) {
      return;
    }

    const popups = document.querySelectorAll('.imagemappopup') || [];
    popups.forEach((element: any) => {
      element.classList.remove("visible");
    });
  }, []);

  useEffect(() => {
    // Simple fix to restrict view bounds when preview is running
    if (previewIsActive) {
      //setMinScale(1920/window.innerWidth);
      if (wrapperRef.current) {
        //wrapperRef.current.zoomIn(0, 0);
      }
    }
  }, [previewIsActive, setMinScale, minScale, wrapperRef, calibrateMarkers]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) ||(data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={Boolean(backgroundImage?.url)}>
      {data?.content?.background?.backgroundColor && (<StaticBackground id="image" backgroundColor={data?.content?.background?.backgroundColor}/>)}
        {backgroundImage?.url && (
          <TransformWrapper ref={wrapperRef} limitToBounds centerZoomedOut={true} minScale={minScale} maxScale={minScale * (data?.content?.settings?.zoomLevel || 4)} disablePadding={true} onZoom={ref => calibrateMarkers(ref)} onZoomStop={ref => calibrateMarkers(ref)} onWheel={ref => calibrateMarkers(ref)} onInit={ref => calibrateMarkers(ref, previewIsActive)} doubleClick={{disabled: true}}>
            <TransformComponent wrapperStyle={{width: "unset"}}>
              {backgroundImage && (<BackgroundImage onLoad={(e: any) => { showImageWhenLoaded(e); getImageBounds(); }} src={MEDIA_PREFIX + backgroundImage?.url} className="loading imagemapBackground" ref={imageRef}/>)}
              <MarkerWrapper ref={markersRef} onClick={e => hideAllPopups(e)} className="imagemapMarkers">
                {data?.content?.records?.list?.map((item, i) => {
                  if (item?.content?.markerType === "annotation") {
                    return <ImageMapAnnotation key={`mapannotation-${i}`} documentId={item?.to_document_id} markerPosition={{x: item?.content?.xPos, y: item?.content?.yPos}} textPosition={{x: item?.content?.annotationXPos, y: item?.content?.annotationYPos}} title={item?.content?.title} subtitle={item?.content?.subtitle} markerColor={data?.content?.settings?.marker?.markerColor}/>
                  } else if (item?.content?.markerType === "clickzone") {
                    return <ImageMapClickZone key={`mapclickzone-${i}`} documentId={item?.to_document_id} startPosition={{x: item?.content?.xPos, y: item?.content?.yPos}} width={item?.content?.zoneWidth} height={item?.content?.zoneHeight} textPosition={{x: item?.content?.annotationXPos, y: item?.content?.annotationYPos}} title={item?.content?.title} subtitle={item?.content?.subtitle} markerColor={data?.content?.settings?.marker?.markerColor}/>
                  } else {
                    return <ImageMapMarker key={`mapmarker-${i}`} documentId={item?.to_document_id} markerStyle={data?.content?.settings?.marker?.markerStyle} markerPosition={{x: item?.content?.xPos, y: item?.content?.yPos}} image={item?.content?.image} title={item?.content?.title} subtitle={item?.content?.subtitle} markerColor={data?.content?.settings?.marker?.markerColor} callback={updateMarkerViewport}/>
                  }
                })}
              </MarkerWrapper>
            </TransformComponent>
          </TransformWrapper>
        )}
      </PageTransition>
    </>
  );
}

export default ImageMapView;