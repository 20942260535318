import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nb_noCommon from "./nb_no/common.json";
import nn_noCommon from "./nn_no/common.json";
import enCommon from "./en/common.json";
import frCommon from "./fr/common.json";
import esCommon from "./es/common.json";
import deCommon from "./de/common.json";
import svCommon from "./sv/common.json";
import plCommon from "./pl/common.json";
import seCommon from "./se/common.json";
import smaCommon from "./sma/common.json";

/**
 * I18n language configuration settings
 */
export const resources = {
  no: { common: nb_noCommon },
  nb: { common: nb_noCommon },
  nn: { common: nn_noCommon },
  sv: { common: svCommon },
  en: { common: enCommon },
  de: { common: deCommon },
  fr: { common: frCommon },
  es: { common: esCommon },
  pl: { common: plCommon },
  se: { common: seCommon },
  sma: { common: smaCommon }
};

i18n
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    react: {
      useSuspense: false,
    },
    fallbackLng: {
      'no': ['nb', 'nn'],
      'nb': ['sv', 'en'],
      'nn': ['nb'],
      'sv': ['nb', 'en'],
      'en': ['fr', 'nb', 'sv'],
      'se': ['nb', 'sv', 'en'],
      'sma': ['sv', 'nb', 'nn', 'en'],
      'es': ['en', 'fr', 'nb', 'se'],
      'fr': ['en', 'es', 'nb', 'se'],
      'de': ['en', 'nb', 'se'],
      'pl': ['en'],
      'default': ['en']
    },
    ns: ["common", "db"],
    defaultNS: "common",
    lowerCaseLng: true,
    cleanCode: true,
    resources,
  });

export default i18n;
