import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface ButtonProps {
  layout?: "outline" | "filled" | "transparent",
  link?: string,
  onClick?: () => void,
  children: JSX.Element | JSX.Element[] | string,
  className?: string
}

export const ButtonContainer = styled.div`
  margin-bottom: 30px;
  padding: 0 25px;
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ButtonElement = styled.button`
  padding: 8px 15px;
  min-height: 42px;
  display: block;
  border-radius: 110px;
  background-color: ${p => p.theme.accentColor};
  color: #fff;
  font-size: .9em;
  font-weight: bold;
  text-align: center;
  border: none;
  min-width: 150px;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.outline {
    border: 1px solid ${p => p.theme.accentColor};
    background: transparent;
    color: ${p => p.theme.accentColor};
  }

  &.transparent {
    border: none;
    background: transparent;
    color: ${p => p.theme.accentColor};
  }

  &:active {
    opacity: .8;
  }
`;

/**
 * Render a horizontal button
 * @returns {JSX.Element} Component template
 */
const Button: FC<ButtonProps> = ({children, layout, link, onClick, className}) => {
  const navigate = useNavigate();

  /**
   * Navigate to url
   * @param {string} url Link to access
   */
  const navigateTo = useCallback((url) => {
    if (url) {
      navigate(url);
    } else {
      onClick && onClick();
    }
  }, [navigate, onClick]);

  return (
    <ButtonElement className={`${layout} ${className}`.trim()} onClick={() => navigateTo(link)}>{children}</ButtonElement>
  );
}

export default Button;