
/**
 * Convert date to DD.MM.YYYY format
 * @param {number} date Timestamp to process
 * @returns {string} Nice date format
 */
export function formatDate(timestamp: number) {
  const dateObject = new Date(timestamp);
  const dd = String(dateObject?.getDate()).padStart(2, '0');
  const mm = String(dateObject?.getMonth() + 1).padStart(2, '0');
  const yyyy = dateObject?.getFullYear();

  return `${dd}.${mm}.${yyyy}`;
}