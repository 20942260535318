import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Kulturio from '@ekultur/kulturio-bridge';
import styled from 'styled-components';

const SecretButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
`;

/**
 * Render a hidden button in the bottom right corner. If the user touches it for 10 seconds, the cache will be cleared
 * @returns {JSX.Element} Component template
 */
const ContentUpdateButton: FC = () => {
  const touchTimer = useRef<number | undefined>(undefined);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (Kulturio.inKulturioApp() && Kulturio.getConfig("interface.secretSync")) {
      setIsVisible(true);
    }
  }, []);

  /**
   * Listen for 
   */
  const handleTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    touchTimer.current = window.setTimeout(updateContentAndRestart, 10000);
  }, []);

  /**
   * Clear touch timer on touch end
   */
  const handleTouchEnd = useCallback(() => {
    clearTimeout(touchTimer.current);
  }, []);

  /**
   * Update content and restart, via command to the Kulturio Desktop App
   */
  const updateContentAndRestart = () => {
    if (Kulturio.inKulturioApp() && Kulturio.getConfig("interface.secretSync")) {
      fetch(`http://localhost:7000/cache/documents/clear`, { method: 'POST' });
      setTimeout(() => {
        fetch(`http://localhost:7000/restart`, { method: 'POST' });
      }, 200);
    }
  };

  if (!isVisible) { return null }

  return (
    <SecretButton onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} />
  );
}

export default ContentUpdateButton;