import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import { getPresentationComponent } from '../common/Common';
import ErrorMessageView from '../components/ErrorMessage';
import LoadingOverlay from '../components/LoadingOverlay';
import { AppContext } from '../contexts/AppContext';
import useFetchDocument from '../hooks/useFetchDocument';
import { fetchDocument } from '../services/Api';
import { DEVICE_SCHEMA_ID } from '../Settings';
import HomeScreenView from './HomeScreen';

interface DeviceHandleProps {
  previewData?: any
}

/**
 * Checks the device ID and renders the presentations. Displays an error message if the presentation was not found.
 * @returns {JSX.Element} Component template
 */
const DeviceHandle: FC<DeviceHandleProps> = ({previewData=null}) => {
  const { deviceId } = useParams<{deviceId: string}>();
  const navigate = useNavigate();
  const { setDeviceData, setDeviceId } = useContext(AppContext);
  const [currentComponent, setCurrentComponent] = useState<JSX.Element>();
  const [data, error, isLoading] = useFetchDocument(deviceId, previewData, true);
  const { t } = useTranslation();

  // Store deviceId
  useEffect(() => {
    !isNaN(Number(deviceId)) && setDeviceId(deviceId);
  }, [deviceId, setDeviceId]);

  // An error occurred? Hard refresh page in 10 seconds.
  useEffect(() => {
    if (error && !isNaN(Number(deviceId))) {
      console.log("An error occurred. Restarting app in 10 seconds...");
      setTimeout(() => {
        navigate(`/device/${deviceId}/`);
        window.location.reload();
      }, 10000);
    }
  }, [error, deviceId, navigate]);

  // Data is fetched
  useEffect(() => {
    (async () => {
      if (data) {
        setDeviceData(data?.content);
        const titleOverride = data?.content?.general?.showTitle ? data?.content?.general?.title : undefined;

        // Should menu or first presentation be displayed?
        if (data?.content?.general?.startScreen === "first") {
          // Get first presentation
          const presentation = await fetchDocument(data?.content?.presentations?.list?.[0]?.to_document_id);

          // Get presentation type
          const presentationType = presentation?.content?.general?.presentationType || "article";

          // Display the first presentation
          setCurrentComponent(getPresentationComponent(presentationType, presentation?.content, titleOverride));
        }
      }
    })();
  }, [data, setDeviceData, setCurrentComponent])

  // Has an error occurred?
  if (error || data?.status === "error") {
    return <ErrorMessageView title={t("deviceNotFound.title")} body={t("deviceNotFound.body")}/>
  }

  // Was the presentation not found?
  if (!previewData && !isLoading && deviceId && (data?.status && !data?.title)) {
    return <ErrorMessageView title={t("deviceNotFound.title")} body={t("deviceNotFound.body")}/>
  }

  // Wrong schema ID?
  if (data?.schema_id && data?.schema_id !== DEVICE_SCHEMA_ID) {
    return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")}/>
  }

  // Is the device Id missing?
  if (!previewData && !isLoading && (!deviceId || isNaN(Number(deviceId)))) {
    return <ErrorMessageView title={t("missingId.title")} body={t("missingId.body")}/>
  }

  // Only display if published
  if (data?.status === "draft") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }
 
  return (
    <>
      <LoadingOverlay isOpen={!data?.status}/>
      { data?.content?.general?.startScreen === "first" ? currentComponent : <HomeScreenView data={data?.content} layout={data?.content?.general?.startScreen}/>}
    </>
  );
}

export default DeviceHandle;
