import { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { generateCSSFilters, getImageUrl, showImageWhenLoaded } from '../common/Media';
import { AppContext } from '../contexts/AppContext';
import useFetchMedia from '../hooks/useFetchMedia';
import { MEDIA_PREFIX } from '../Settings';

interface BackgroundImageProps {
  media?: any,
  backgroundColor?: string
}

const ImageElement = styled.img.attrs((p: any) => ({
  focalX: p.focalX || "50",
  focalY: p.focalY || "50",
  filters: p.filters,
  backgroundColor: p.backgroundColor || null
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${p => p.focalX}% ${p => p.focalY}%;
  filter: ${p => p.filters};
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateY(0);
  ${p => p.backgroundColor && `background-color: ${p.backgroundColor}`}
`

const StaticBackground = styled.div.attrs((p: any) => ({
  backgroundColor: p.backgroundColor || null
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translateY(0);
  ${p => p.backgroundColor && `background-color: ${p.backgroundColor}`}
`;

/**
 * Render a background image. Used by most views.
 * @returns {JSX.Element} Component template
 */
const BackgroundImage: FC<BackgroundImageProps> = ({media, backgroundColor}) => {
  const { previewIsActive } = useContext(AppContext)
  const [backgroundImage] = useFetchMedia(media, { dimension: "max" });
  const [filterCss, setFilterCss] = useState<string | undefined>(undefined);

  // Parse CSS filters from media object
  useEffect(() => {
    if (media?.filters) {
      setFilterCss(generateCSSFilters(media?.filters));
    }
  }, [media]);

  if (backgroundImage?.url) {
    return <ImageElement className={previewIsActive ? "" : "loading"} onLoad={showImageWhenLoaded} src={`${MEDIA_PREFIX}${getImageUrl(backgroundImage?.url)}`} focalX={backgroundImage?.focalPoint?.x} focalY={backgroundImage?.focalPoint?.y} filters={filterCss} backgroundColor={backgroundColor}/>
  } else if (backgroundColor) {
    return <StaticBackground backgroundColor={backgroundColor}/> 
  } else {
    return null;
  }
}

export default BackgroundImage;