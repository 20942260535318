import { FC, useCallback, useContext, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as IconCheck } from '../assets/icon_check.svg';
import { ReactComponent as IconTrophy } from '../assets/icon_trophy.svg';
import { ReactComponent as IconFault } from '../assets/icon_fault.svg';
import { ReactComponent as IconWarning } from '../assets/icon_warning.svg';
import Button from './Button';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .5s;
`;

const WrapperEnterence = keyframes`
  from { transform: translate(-50%, 100%); }
  to { transform: translate(-50%, -50%); }
`

const PopupWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  min-height: 10%;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
  animation: ${WrapperEnterence} linear 0.2s;
`;

const Title = styled.h1`
  color #000;
  font-size: 1.4em;
  margin-top: 0;
  text-align: center;

  &.moveDown {
    margin-top: 10px;
  }
`;

const Body = styled.div`
  color: #000;
  margin: 20px 0;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-50%);
  background: ${p => p.theme.accentColor};
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;

  & svg {
    height: 20px;
    width: 20px;
  }
`;

/**
 * Render a popup with contents from app context
 * @returns {JSX.Element} Component template
 */
const Popup: FC = () => {
  const { popup, setPopup } = useContext(AppContext);
  const [icon, setIcon] = useState<JSX.Element>();
  const [prevActiveElement, setPrevActiveElement] = useState<any>(null);

  // Get icon based on popup.icon
  useEffect(() => {
    switch(popup?.icon) {
      case "solved":
        setIcon(<IconCheck/>);
        break;
      case "trophy":
        setIcon(<IconTrophy/>)
        break;
      case "fault":
        setIcon(<IconFault/>)
        break;
      case "warning":
        setIcon(<IconWarning/>)
        break;
    }
  } , [popup])

  /**
   * Popup is opened
   */
  useEffect(() => {
    if (popup) {
      // Get previous active elament
      if (document.activeElement) {
        setPrevActiveElement(document.activeElement);
      }

      document.body.classList.add("modal");
      document.getElementById("main")?.setAttribute("aria-hidden", "true");
    } else {
      setPrevActiveElement(null);
    }
  }, [popup]);

  /**
   * Close popup
   */
  const closePopup = useCallback((callback?: () => void) => {
    document.body.classList.remove("modal");
    document.getElementById("main")?.setAttribute("aria-hidden", "false");

    // Focus prev element
    if (prevActiveElement) {
      prevActiveElement?.focus();
    }

    // Hide popup
    setPopup(null);

    // Run callback
    if (callback) {
      callback();
    }
  }, [setPopup, prevActiveElement]);

  if (!popup || !prevActiveElement) { return null; }

  return (
    <Overlay role="presentation" tabIndex={-1}>
      <PopupWrapper role="dialog" aria-modal="true" aria-labelledby="popuptitle">
        {icon && (<Icon>{icon}</Icon>)}
        {popup?.title && (<Title id="popuptitle" className={icon ? "moveDown" : undefined}>{popup?.title}</Title>)}
        {popup?.body && (<Body>{popup?.body}</Body>)}
        {popup?.buttons ? (
          <Buttons>
            {popup?.buttons?.map((button: any, i: number) => {
              return <Button key={`button${i}`} onClick={() => closePopup(button.onClick)} aria-label={button.title}>{button.title}</Button>
            })}
          </Buttons>
        ) : (
          <Buttons>
            <Button onClick={closePopup} aria-label={'OK'}><>OK</></Button>
          </Buttons>
        )}
      </PopupWrapper>
    </Overlay>
  )
};

export default Popup;