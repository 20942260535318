import { FC, useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import packageJson from '../../package.json'
import { AppContext } from '../contexts/AppContext';
import { getIcon } from '../common/Common';
import { getTranslation } from '../common/Translation';
import { fetchDocument } from '../services/Api';

interface MenuProps {
  isOpen: boolean,
  close: () => void
}

const MenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: 30;
  background-color: rgba(0, 0, 0, .5);

  &.closed {
    opacity: 0;
    display: none;
  }
`;

const MenuWrapper = styled.aside`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${p => p.theme.scaleFactor * 400}px;
  background-color: ${p => p.theme?.backgroundColor};
  box-shadow: -8px -1px 52px -4px rgba(0, 0, 0, .3);
  z-index: 31;
  transition: transform 0.2s linear;

  &.closed {
    transform: translateX(100%);
    box-shadow: none;
  }
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 110px);
  overflow-y: scroll;
`

const MenuItems = styled.ul`
  margin: ${p => p.theme.scaleFactor * 45}px 0 0;
  padding: 0;
  list-style: none;

  & li {
    cursor: pointer;
  }

  & li a, & li span {
    text-decoration: none;
    color: ${p => p.theme?.textColor};
    background-position: 25px 50%;
    background-repeat: no-repeat;
    padding: ${p => p.theme.scaleFactor * 10}px ${p => p.theme.scaleFactor * 20}px ${p => p.theme.scaleFactor * 10}px ${p => p.theme.scaleFactor * 45}px;
    display: block;
    font-size: 1.2em;
    /*height: 60px;*/
    display: flex;
    align-items:center;
  }

  & li a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  & li svg {
    fill: ${p => p.theme?.textColor};
    width: ${p => p.theme.scaleFactor * 20}px;
    height: ${p => p.theme.scaleFactor * 25}px;
    margin-right: 20px;
    vertical-align: middle;
    flex-shrink: 0;
  }

  & li.active a, & li.active span {
    color: ${p => p.theme?.accentColor};
  }

  & li.active svg {
    fill: ${p => p.theme?.accentColor};
  }

  &.preview {
    pointer-events: none;
  }
`

const VersionFooter = styled.span`
  font-size: .8em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 0.5px solid #464646;
  text-align: right;
  padding: 30px 25px;
`

const VersionNumber = styled.span`
  font-size: 10px;
  opacity: .5;
`

/**
 * Render the right sidebar menu
 * @returns {JSX.Element} Component template
 */
const Menu: FC<MenuProps> = ({isOpen, close}) => {
  const { currentLanguage, setCurrentLanguage, setDeviceLanguages, deviceId, deviceData, deviceMenu, setDeviceMenu, deviceLanguages, previewIsActive } = useContext(AppContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    // Collect device languages and set default language
    if (deviceData?.languages && deviceData?.languages?.length !== deviceLanguages?.length) {
      setDeviceLanguages(deviceData?.languages);
      //setCurrentLanguage(deviceData?.languages?.[0]);
    }

    if (!deviceData || !(Symbol.iterator in Object(deviceData?.presentations?.list)) || deviceData?.presentations?.list?.length < 2) { 
      setDeviceMenu([]);
      return 
    }

    let menuData: any = [];

    (async() => {
      await Promise.all(
        deviceData?.presentations?.list?.map(async (presentation: any, i: number) => {
          const presentationData = await fetchDocument(String(presentation?.to_document_id));
          const presentationId = presentation?.to_document_id;
          const presentationTitle = presentationData?.content?.general?.title || presentationData?.title;
          const presentationTitleOverride = presentation?.content?.title;
          const presentationType = presentationData?.content?.general?.presentationType || "article";
        
          // Collect menu hierarchy
          if (presentationData?.status === "published") {
            menuData[i] = {
              id: presentationId,
              title: presentationTitle,
              titleOverride: presentationTitleOverride,
              type: presentationType,
              url: `/device/${deviceId}/${presentationType}/${presentationId}`,
              languages: Object.keys(presentationData?.content?.general?.title || []) || []
            };
          }
    
          return menuData;
        })
      ).then(a => {
        // Add home page to the menu if needed
        if (deviceData?.general?.startScreen === "menu") {
          menuData.unshift({
            title: "t(menu.home)",
            type: "home",
            url: `/device/${deviceId}`
          });
        }
        setDeviceMenu(menuData)
      });
    })();
  }, [deviceId, deviceData, deviceLanguages, setDeviceLanguages, setCurrentLanguage, setDeviceMenu])

  const closeAfterClick = useCallback(() => {
    setTimeout(() => {
      close();
    }, 200);
  }, [close]);

  /**
   * Returns true if current page is home
   */
   const isHome = /device\/(\d+)(\/)?$/.test(window?.location?.hash)

  return (
    <>
    {isOpen && (<MenuBackdrop className={isOpen ? "open" : "closed"} onClick={close}></MenuBackdrop>)}
    <MenuWrapper className={isOpen ? "open" : "closed"}>
      <MenuItemsWrapper>
        <MenuItems className={previewIsActive ? "preview" : undefined}>
          {deviceMenu?.map((link, i) => {
            // Use i18n if title is formatted as "t(Lorem ipsum)"
            const i18match = (String(link?.title).match(/t\((.*)\)/));
            const isActive = window?.location?.href.endsWith(link?.url) || (isHome && i === 0);
            const titleOverride = getTranslation(link?.titleOverride, currentLanguage)?.trim()?.length > 0 ?  getTranslation(link?.titleOverride, currentLanguage) : undefined;
            const title = i18match ? t(i18match) : titleOverride || getTranslation(link?.title, currentLanguage);
            if (link?.languages?.includes(currentLanguage) || link.type === "home") {
              return <li key={`linkitem-${i}`} className={isActive ? "active" : undefined}><Link to={link?.url} onClick={closeAfterClick}>{getIcon(link?.type)}{title}</Link></li>
            } else {
              return null;
            }
          })}
        </MenuItems>
      </MenuItemsWrapper>
      <VersionFooter>
        {t("menu.poweredBy")} {packageJson.displayName} <VersionNumber>v{packageJson.version}</VersionNumber>
      </VersionFooter>
    </MenuWrapper>
    </>
  )
}
export default Menu;