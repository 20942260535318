import { FC } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import NotFoundView from './views/404';
import AutoPlayView from './views/Autoplay';
import DeviceHandle from './views/Device';
import ImageMapView from './views/ImageMap';
import ListView from './views/List';
import MediaGalleryView from './views/MediaGallery';
import MinneView from './views/MinneView';
import MosaicView from './views/Mosaic';
import PreviewView from './views/Preview';
import SimulatorView from './views/Simulator';
import SingleView from './views/Single';
import SplashScreen from './views/SplashScreen';
import TimelineView from './views/Timeline';
import IframeView from './views/Iframe';

export interface ContainerProps {
  children: JSX.Element
}

const RouteDeclaration: FC = () => {
  const location = useLocation()?.pathname;

  return (
    <>
      { /* Keep these pages always alive */}
      <IframeView active={ location.includes("/iframe/") }/>

      <Routes>
        <Route path="/" element={<SplashScreen />}/>
        <Route path="/simulator" element={<SimulatorView/>}/>
        <Route path="/simulator/:deviceId" element={<SimulatorView/>}/>
        <Route path="/device/:deviceId" element={<DeviceHandle/>}/>
        <Route path="/device/:deviceId/single/:documentId" element={<SingleView/>}/>
        <Route path="/device/:deviceId/article/:documentId" element={<SingleView/>}/>
        <Route path="/device/:deviceId/list/:documentId" element={<ListView/>}/>
        <Route path="/device/:deviceId/mosaic/:documentId" element={<MosaicView/>}/>
        <Route path="/device/:deviceId/timeline/:documentId" element={<TimelineView/>}/>
        <Route path="/device/:deviceId/imagemap/:documentId" element={ <ImageMapView/>}/>
        <Route path="/device/:deviceId/gallery/:documentId" element={<MediaGalleryView/>}/>
        <Route path="/device/:deviceId/autoplay/:documentId" element={<AutoPlayView/>}/>
        <Route path="/device/:deviceId/minner/:responseId" element={<MinneView country="no"/>}/>
        <Route path="/device/:deviceId/minnen/:responseId" element={<MinneView country="sv"/>}/>
        <Route path="/preview" element={<PreviewView/>}/>
        <Route path="*" element={<NotFoundView/>}/>
      </Routes>
    </>
  );
};

export default RouteDeclaration;
