import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../contexts/AppContext';
import PlayIcon from "../assets/icon_play.svg";
import { MEDIA_PREFIX } from '../Settings';

interface MediaPopupProps {
  item: any,
  close?: () => void,
  caption?: string
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: initial !important;
`

const VideoWrapper = styled.div`
  width: 100%;
  max-height: 100vh;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

const Video = styled.video`
  width: 100%;
  max-height: 100vh;
  background: transparent;

  ::cue {
    line-height: 60px;
  }
`

const PlayControl = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, .5);

  &&:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: ${p => p.theme.accentColor};
    background-image: url(${PlayIcon});
    background-position: 60% 50%;
    background-size: 30px;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 10;
  }
`

const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10px;
  background-color: ${p => p.theme.backgroundColor};

  &::after {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 50%;
    left: 0;
    content: "";
    transform: translateY(-50%);
  }
`

const ProgressBar = styled.div`
  width: 0%;
  height: 10px;
  background-color: ${p => p.theme.accentColor};
  transition: width 200ms;
`

const SubtitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, .8);
  max-height: 150px;
  overflow: hidden;
  

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 90%);
    width: 100%;
    height: 4em;
  }
`

const Subtitle = styled.div`
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 20px;
  width: 100%;
  overflow: auto;
` 

const Caption = styled.div`
  display: none;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  padding: 50px;
  background: linear-gradient(0deg, black, transparent);
`

/**
 * Render a fullscreen popup for displaying images or videos
 * @returns {JSX.Element} Component template
 */
const VideoPopup: FC<MediaPopupProps> = ({item, close, caption}) => {
  const { currentLanguage, setMediaIsPlaying, globalVolume, reset } = useContext(AppContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const progressWrapperRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [mediaUrl, setMediaUrl] = useState<string | null>(null);
  const [closedCaptions, setClosedCaptions] = useState<any>(null);

  /**
   * Update progress bar when video is playing
   * @param e Event
   */
  const updateProgressBar = useCallback((e: any) => {
    if (progressRef.current) {
      const currentPosition = (e.target.currentTime / e.target.duration) * 100;
      progressRef.current.style.width = `${currentPosition}%`;
      setMediaIsPlaying(true);
      reset && reset();
    }
  }, [setMediaIsPlaying, reset]);

  /**
   * Stop all audio
   */
   const stopAllAudio = useCallback(() => {
    const audioElements = document.querySelectorAll("audio");
    audioElements.forEach(el => {
      el.pause();
      el.currentTime = 0;
    });
  }, []);

  /**
   * Set currentTime based on click
   * @param e Event
   */
  const setProgressPosition = useCallback((e: any) => {
    if (videoRef.current && progressWrapperRef.current) {
      const clickedPositionPercentage = e.nativeEvent.offsetX / progressWrapperRef.current.clientWidth;
      const clickedPositionSeconds = videoRef.current.duration * clickedPositionPercentage;
      if (isNaN(clickedPositionSeconds)) { return }
      videoRef.current.currentTime = clickedPositionSeconds;
    }
  }, []);

  /**
   * Toggle between play and pause
   * @param e Event
   */
  const togglePlay = useCallback((e: any) => {
    if (e.target.paused) {
      setIsPlaying(true);
      setMediaIsPlaying(true);
      e.target.play();
    } else {
      setIsPlaying(false);
      setMediaIsPlaying(false);
      e.target.pause();
    }
  }, [setMediaIsPlaying]);

  // Get closed captions
  useEffect(() => {
    if (item?.closed_captions?.[currentLanguage || "no"]) {
      const encoder = new TextEncoder();
      const uint8Array = encoder.encode(item?.closed_captions?.[currentLanguage || "no"]);
      const vttBase64 = btoa(String.fromCharCode.apply(null, Array.from(uint8Array)));

      setClosedCaptions(`data:plain/text;base64,${vttBase64}`);
    } else {
      setClosedCaptions(null);
    }
  }, [currentLanguage, item]);

  // Hide caption element in photoswipe lightbox
  useEffect(() => {
    const captionElement = document.querySelector(".pswp__default-caption") as HTMLDivElement;
    if (captionElement) { captionElement.style.display = "none"; } 
  }, []);

  // Get media URL
  useEffect(() => {
    setMediaUrl(item.originalUrl || item.src || item.url);
    setMediaIsPlaying(false);
    stopAllAudio();
  }, [item, stopAllAudio, setMediaIsPlaying]);

  // Update volume if global volume changes
  useEffect(() => {
    const localVolume = (item?.volume || 75) * 0.01;

    if (videoRef.current && mediaUrl) {
      if (globalVolume !== undefined) {
        videoRef.current.volume = globalVolume * localVolume;
      } else {
        videoRef.current.volume = localVolume;
      }
    }
  }, [globalVolume, item, mediaUrl]);

  /**
   * Run when video has ended
   * @param e Event
   */
  const onEnded = useCallback((e: any) => {
    setIsPlaying(false);
    close && close();
    setMediaIsPlaying(false);
  }, [close, setMediaIsPlaying]);

  return (
    <>
      {mediaUrl && (
        <>
          <Container>
            <VideoWrapper>
              {!isPlaying && (<PlayControl/>)}
              <Video autoPlay src={MEDIA_PREFIX + mediaUrl} poster={MEDIA_PREFIX + item?.thumbnail_src || MEDIA_PREFIX + item?.thumbUrl} ref={videoRef} onTimeUpdate={updateProgressBar} onClick={togglePlay} onEnded={onEnded} className="clickable">
                {closedCaptions && (
                  <track default kind="subtitles" src={closedCaptions} />
                )}
              </Video>
              {(caption && caption?.length > 0 && !item.caption) && (<Caption dangerouslySetInnerHTML={{__html: caption || ""}}/>)}
              <ProgressBarWrapper ref={progressWrapperRef} onClick={setProgressPosition} className="clickable"><ProgressBar ref={progressRef}/></ProgressBarWrapper>
            </VideoWrapper>
          </Container>
          {item.caption && (<SubtitleWrapper><Subtitle>{item.caption}</Subtitle></SubtitleWrapper>)}
        </>
      )}
    </>
  )
}

export default VideoPopup;