import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface ErrorMessageProps {
  title: string,
  body: string,
  redirect?: boolean
}

const Wrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Circle = styled.div`
  margin: 80px auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${p => p.theme?.accentColor};
  color: ${p => p.theme?.textColor};
  width: 125px;
  height: 125px;
  border-radius: 125px;
  font-size: 70px;
  font-weight: bold;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
`

const Message = styled.p`
  text-align: center;
`

const RedirectButton = styled.p`
  text-align: center;
  background: #fff;
  color: #000;
  padding: 10px 25px;
  border-radius: 25px;
  display: table;
  margin: 40px auto;
`

/**
 * Render a full screen error message
 * @returns {JSX.Element} Component template
 */
const ErrorMessageView: FC<ErrorMessageProps> = ({title, body, redirect}) => {
  const { t } = useTranslation();
  const { deviceId } = useContext(AppContext);
  const [, setResetTimer] = useState<any>(null);
  const navigate = useNavigate();

/**
 * Redirect to front page
 * @param deviceId Device ID
 */
 const goToFrontPage = useCallback((deviceId?: number) => {
  if (deviceId) {
    navigate(`/device/${deviceId}`)
  }
}, [navigate]);

  useEffect(() => {
    if (redirect) {
      setResetTimer(setTimeout(() => { goToFrontPage(deviceId) }, 10000));
    }
  }, [setResetTimer, deviceId, redirect, goToFrontPage]);

  return (
    <Wrapper>
    <Circle>!</Circle>
    <Title>{title}</Title>
    <Message>{body}</Message>
    {(redirect && typeof deviceId === "number") && (<RedirectButton onClick={() => goToFrontPage(deviceId)}>{t("redirectToFrontpage")}</RedirectButton>)}
  </Wrapper>
  );
}

export default ErrorMessageView;