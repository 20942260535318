import { FC } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';

interface BlockTitleProps {
  text: string,
  level?: "h1" | "h2" | "h3" | "anchor"
}

const Container = styled(BlockContainer)`
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  clear: both;
  width: 100%;
  font-size: 1.2em;
  line-height: 1.2em;
  margin: 0;
`;

const AnchorElement = styled.h2`
  padding: 25px 0 0;
  clear: both;
  width: 100%;
  font-size: 1.4em;
  line-height: 1.4em;
  margin: 0;
`

/**
 * Render an article subtitle
 * @returns {JSX.Element} Component template
 */
const BlockTitle: FC<BlockTitleProps> = ({text, level}) => {

  if (text && text.length > 0) { 
    return (
      <Container>
        {level === "anchor" ? (<AnchorElement>{text}</AnchorElement>) : (<Title>{text}</Title>)}
      </Container>
    );
  }

  return null;
}

export default BlockTitle;