import { LANGUAGES } from "../Settings";

/**
 * Get translation
 * @param {string|object} property Property path
 * @param {string} language Language to be displayed. Default: "no"
 * @returns 
 */
 export const getTranslation = (property: any, currentLanguage?: string) => {
  if (!currentLanguage) { currentLanguage = "no"; }

  if (typeof property === "string") {
    return property;
  } else if (typeof property === "object") {
    if (property?.[currentLanguage]) {
      // Is a translation for the current language found?
      return property?.[currentLanguage];
    } else {
      // Could we find any translation within a fallback language?
      let availableLanguages: string[] = [];
      if (property) {
        const availableLanguageKeys = Object.keys(property);
        availableLanguageKeys?.forEach(langKey => {
          if (property?.[langKey] && property?.[langKey] !== "") {
            availableLanguages.push(langKey);
          }
        });  


        if (availableLanguages?.length > 0) {  
          // Check for siblings
          let siblingLanguage;
          availableLanguageKeys?.forEach(langKey => {
            if (!siblingLanguage && isSiblingLanguage(currentLanguage || "no", langKey)) {
              siblingLanguage = langKey;
            }
          });

          if (siblingLanguage) {
            return property?.[siblingLanguage]
          }

          // Check for fallback language
          const fallbackLanguage = getFallbackLanguage(currentLanguage, availableLanguages);
          if (fallbackLanguage) {
            return property?.[fallbackLanguage];  
          }
        }
      }
    }
  }

  return "";
}

/**
 * Get fallback language
 * @param {string} currentLanguage Current app language
 * @param {array} availableLanguages List of available languages
 * @returns {string} Fallback language
 */
 export const getFallbackLanguage = (currentLanguage: string, availableLanguages: string[]) => {
  let languageCheque: string[] = [];
  
  if (availableLanguages.includes(currentLanguage)) {
    return currentLanguage;
  }

  LANGUAGES.forEach(language => {
    if (language?.key === currentLanguage && language?.fallback) {
      languageCheque = language?.fallback;
    }
  });

  let fallbackLanguage: string|undefined = undefined;
  languageCheque?.forEach((lang) => {
    if (!fallbackLanguage && availableLanguages.includes(lang)) {
      fallbackLanguage = lang;
    }

    // Check for siblings
    LANGUAGES.forEach(language => {
      if (language?.key === lang && language?.siblings) {
        language?.siblings?.forEach(sibling => {
          if (availableLanguages?.includes(sibling)) {
            fallbackLanguage = sibling;
          }
        })
      }
    });
  });
 
  return fallbackLanguage || currentLanguage;
}

/**
 * Check if language is a sibling with a fallback language.
 * Example: "nb_no" (bokmål) is based on the same language as "no" and "nn_no" (nynorsk)
 * @param {string} currentLanguage Current app language
 * @param {array} fallbackLanguage List of available languages
 * @returns {boolean} Returns true if sibling is found
 */
 export const isSiblingLanguage = (currentLanguage: string, fallbackLanguage: string) => {
  let isSibling: boolean = false;

  LANGUAGES.forEach(language => {
    if (typeof language?.siblings === "object") {
      if (language?.key === currentLanguage && language?.siblings?.includes(fallbackLanguage)) {
        isSibling = true;
      }
    }
  });

  return isSibling;
}