import { FC, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { getContentType } from '../common/Common';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import useFetchDocument from '../hooks/useFetchDocument';
import Link from './Link';

interface ImageMapAnnotationProps {
  documentId: number,
  title?: string,
  subtitle?: string,
  textPosition?: { x: number, y: number },
  markerPosition: { x: number, y: number },
  markerColor?: string
}

const AnnotationWrapper = styled.div.attrs((p: any) => ({
  zoom: p.zoom || undefined
}))`
  position: absolute;
  z-index: 10;
  transform-origin: 50% 50%;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  max-width: 300px;
  min-width: 200px;
  min-height: 60px;
  top: -50px;
  left: 70px;
  zoom: ${p => p.zoom};
  transform: translate(-50%,-50%) translate3d(0, 0, 0);
  pointer-events: all;
  box-shadow: 0 0 15px 15px rgb(0 0 0 / 5%);

  &:active {
    opacity: .8;
  }
`

const ArrowWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 11;
  pointer-events: none;
`;

const Arrow = styled.div`
  position: absolute;
  border-color: #fff;
  border-style: solid;
  z-index: 14;
  border-radius: 2px;
  pointer-events: none;
`;

const Marker = styled.div.attrs((p: any) => ({
  markerColor: p.markerColor || p.theme.accentColor
}))`
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0 0 15px 15px rgb(0 0 0 / 5%);
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  z-index: 15;

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: ${p => p.markerColor};
  }
`;

const Title = styled.span`
  color: #000;
  font-weight: bold;
  font-size: 1.2em;
  display: block;
  position: relative;
  text-align: center;
`

const Subtitle = styled.span.attrs((p: any) => ({
  accentColor: p.accentColor || undefined
}))`
  color: #000;
  font-size: .9em;
  display: block;
  letter-spacing: .8px;
  text-align: center;

  &::before {
    position: relative;
    content: "";
    background-color: ${p => p.accentColor || p.theme.accentColor};
    height: 5px;
    margin: 8px auto;
    width: 30px;
    display: block;
    color: #a50b30;
  }
`

const AnnotationLink = styled(Link)`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 50px 100px;
    width: -webkit-fill-available;
    transform: translate(-50%, -50%);
  }
`;

/**
 * Render an annotation component for the imagemap view
 * @returns {JSX.Element} Component template
 */
const ImageMapAnnotation: FC<ImageMapAnnotationProps> = ({documentId, title, subtitle, textPosition, markerPosition, markerColor}) => {
  const { currentLanguage } = useContext(AppContext);
  const annotationRef = useRef<HTMLDivElement>(null);
  const [markerTitle, setMarkerTitle] = useState<any>(null);
  const [markerSubtitle, setMarkerSubtitle] = useState<any>(null);
  const [contentType, setContentType] = useState<string>("");
  const [annotationPosition, setAnnotationPosition] = useState<any>(null);
  const [arrow, setArrow] = useState<any>(null);
  const [data] = useFetchDocument(documentId);

  // Get title
  useEffect(() => {
    if (data) {
      setMarkerTitle(getTranslation(title, currentLanguage)?.length > 0 ? getTranslation(title, currentLanguage) : getTranslation(data?.title, currentLanguage));
      setMarkerSubtitle(getTranslation(subtitle, currentLanguage) || null);
    }
  }, [title, currentLanguage, data, subtitle]);

  // Get content type of a presentation or article
  useEffect(() => {
    setContentType(getContentType(data))
  }, [data]);

  // Get position of annotation bubble
  useEffect(() => {
    if (textPosition?.x || textPosition?.y) {
      setAnnotationPosition(textPosition);
    } else {
      if (markerPosition?.x > 50) {
        setAnnotationPosition({x: 85, y: markerPosition?.y + 6});
      } else {
        setAnnotationPosition({x: 15, y: markerPosition?.y + 6});
      }
    }
  }, [textPosition, markerPosition]);

  // Calculate arrow size
  useEffect(() => {
    let x: number, y: number, w: number, h: number, border: string[] = [];

    // Get x and width
    if (markerPosition?.x > annotationPosition?.x) {
      x = annotationPosition?.x;
      w = markerPosition?.x - annotationPosition?.x;
      border.push("left");
    } else {
      x = markerPosition?.x;
      w = annotationPosition?.x - markerPosition?.x;
      border.push("right");
    }

    // Get y and height
    if (markerPosition?.y > annotationPosition?.y) {
      y = annotationPosition?.y;
      h = markerPosition?.y - annotationPosition?.y;
      border.push("bottom");
    } else {
      y = markerPosition?.y;
      h = annotationPosition?.y - markerPosition?.y;
      border.push("top");
    }

    // Define border of arrow div
    const borderWidth = `${border.includes("top") ? "3px" : "0"} ${border.includes("right") ? "3px" : "0"} ${border.includes("bottom") ? "3px" : "0"} ${border.includes("left") ? "3px" : "0"}`
    
    setArrow({x: `${x}%`, y: `${y}%`, w: `${w}%`, h: `${h}%`, borderWidth: borderWidth});
  }, [markerPosition, annotationPosition]);

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return null; }

  return (
    <ArrowWrapper className="imagemapelement" style={{transformOrigin: `${markerPosition.x}% ${markerPosition.y}%`}}>
      <Marker style={{left: `calc(${markerPosition.x}%)`, top: `calc(${markerPosition.y}%)`}} markerColor={markerColor}/>
      <Arrow style={{left: arrow?.x, top: arrow?.y, width: arrow?.w, height: arrow?.h, borderWidth: arrow?.borderWidth}}/>
      <AnnotationWrapper ref={annotationRef} key={`annotation-${documentId}`} style={{left: `calc(${annotationPosition?.x}% - ${0}px)`, top: `calc(${annotationPosition?.y}% - ${0}px)`}}>
        <AnnotationLink id={documentId} presentationType={contentType}>
          <>
            <Title>{markerTitle}</Title>
            {markerSubtitle && (<Subtitle accentColor={markerColor}>{markerSubtitle}</Subtitle>)}
          </>
        </AnnotationLink>
      </AnnotationWrapper>
    </ArrowWrapper>
  );
}

export default ImageMapAnnotation;
