import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

interface AnchorNavigationProps {
  blocks: any
}

const BlockContainer = styled.div`
`;

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  user-select: none;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const AnchorWrapper = styled.nav`
  display: inline-block;
  padding: 0px calc(10% + 50px) 10px;
  margin: 0 0 10px;
`;

const Anchor = styled.button`
  cursor: pointer;
  pointer-events: all !important;
  background: rgba(255,255,255,.1);
  color: #fff;
  border-radius: 40px;
  padding: 10px 20px;
  margin-right: 10px;
  font-weight: bold;
  font-size: .9em;
  border: none;

  &:last-of-type {
    margin-right: 0;
  }

  &:active {
    opacity: .7;
  }
`;

/**
 * Render an anchor navigation
 * @returns {JSX.Element} Component template
 */
const AnchorNavigation: FC<AnchorNavigationProps> = ({blocks}) => {
  const [anchors, setAnchors] = useState<any>([]);

  // Navigate to anchor
  const goToAnchor = useCallback((index: number) => {
    document?.getElementById(`anchor${index}`)?.scrollIntoView();
  }, []);

  // Get anchors from blocks
  useEffect(() => {
    // Append index. Used for IDs
    const blocksWithIndex = blocks?.map((block: any, i: number) => { return {...block, ...{index: i} } });

    setAnchors(blocksWithIndex?.filter((block: any) => block.blockType === "anchor"));
  }, [blocks]);

  if (anchors?.length < 1) { return null; }

  return (
    <BlockContainer>
      <ScrollWrapper>
        <AnchorWrapper>
          {anchors?.map((anchor: any, i: number) => {
            if (anchor?.title?.length > 0) {
              return <Anchor key={`anchor${i}`} onClick={() => { goToAnchor(anchor?.index) }}>{anchor.title}</Anchor>
            } else {
              return null;
            }
          })}
        </AnchorWrapper>
      </ScrollWrapper>
    </BlockContainer>
  )
}

export default AnchorNavigation;