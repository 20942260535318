import { FC, useContext } from 'react';
import styled from 'styled-components';
import { getTranslation } from '../common/Translation';
import { AppContext } from '../contexts/AppContext';
import useFetchMedia from '../hooks/useFetchMedia';
import BlockContainer from './BlockContainer';
import { LightboxItem, LightboxWrapper } from './LightboxModal';

interface BlockDocumentsWrapperProps {
  children: any
}

interface BlockDocumentItemProps {
  media: any
}

const Wrapper = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
  float: left;
  width: 100%;
  display: block;
  list-style: none;

  &:empty {
    display: none;
  }
`;

const BlockDocument = styled.li`
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 30px;
  background-image: url('/img/icon_pdf.png');
  margin: 15px 0;
  padding: 5px 0 5px 40px;
  word-break: break-all;
  display: block;
  min-height: 30px;
`;

/**
 * Render a PDF block container
 * @returns {JSX.Element} Component template
 */
export const BlockDocumentsWrapper: FC<BlockDocumentsWrapperProps> = ({children}) => {
  return (
    <BlockContainer>
      <Wrapper>
        {children}
      </Wrapper>
    </BlockContainer>
  )
}

/**
 * Render a PDF block
 * @returns {JSX.Element} Component template
 */
 export const BlockDocumentItem: FC<BlockDocumentItemProps> = ({media}) => {
  const { currentLanguage } = useContext(AppContext);
  const [mediaObject] = useFetchMedia(media);

  return (
    <LightboxWrapper>
      <LightboxItem media={mediaObject}>
        <BlockDocument>{getTranslation(mediaObject?.title, currentLanguage) || mediaObject?.filename || ""}</BlockDocument>
      </LightboxItem>
    </LightboxWrapper>
  );
}