import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Document, Page } from 'react-pdf';
import { ReactComponent as PrevButton } from '../assets/chevron_left.svg';
import { ReactComponent as NextButton } from '../assets/chevron_right.svg';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { AUTOPLAY_IMAGE_TIMER, MEDIA_PREFIX } from '../Settings';

interface PdfViewerProps {
  item: any,
  close?: any,
  caption?: any,
  autoPlay?: boolean,
  autoPlayDelay?: number,
  autoPlayLoop?: boolean,
  onAutoPlayEnd?: () => void
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PdfPage = styled(Page)`
  & canvas {
    width: 100% !important;
    height: 100vh !important;
    object-fit: contain;
    transition: opacity 1s;
  }
`;

const Button = styled.a`
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 10;
  display: block;
  border-radius: 25px;
  top: 50%;
  transform: translateY(-50%);
  fill: #000;
  pointer-events: all;
  cursor: pointer;
  overflow: hidden;

  & svg {
    width: ${p => p.theme.scaleFactor * 35}px;
    height: ${p => p.theme.scaleFactor * 35}px;
  }

  &.left {
    left: 50px;
  }

  &.right {
    right: 50px;
  }

  &:active {
    opacity: .8;
  }

  &&::before {
    content: "";
    position: absolute;
    padding: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

/**
 * Render a PDF viewer
 * @returns {JSX.Element} Component template
 */
const PdfViewer: FC<PdfViewerProps> = ({item, close, caption, autoPlay, autoPlayDelay, autoPlayLoop, onAutoPlayEnd}) => {
  const autoPlayRef = useRef<NodeJS.Timeout>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  /**
   * Set page count when document is loaded
   */
  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPageCount(numPages);
  }, []);

  /**
   * Go to previous or next page
   * @param {number} index Positive or negative index
   */
  const goToPage = useCallback((index: number) => {
    const newPageNumber = pageNumber + index;
    if (newPageNumber > 0 && newPageNumber <= pageCount) {
      setPageNumber(newPageNumber);
    } else {
      if (autoPlay && !autoPlayLoop && onAutoPlayEnd) {
        onAutoPlayEnd();
      } else {
        setPageNumber(1);
      }
    }
  }, [pageCount, pageNumber, autoPlay, autoPlayLoop, onAutoPlayEnd]);

  // Hide lightbox caption gradient
  useEffect(() => {
    const lightboxCaptionElement = document?.getElementsByClassName("pswp__default-caption")?.[0] as HTMLElement;
    if (lightboxCaptionElement) {
      lightboxCaptionElement.style.display = "none";
    }
  }, []);

  // Auto loop pages if autoplay is active
  useEffect(() => {
    if (autoPlay) {
      const delay = autoPlayDelay || AUTOPLAY_IMAGE_TIMER;
      clearTimeout(autoPlayRef.current);
      autoPlayRef.current = setInterval(() => {
        goToPage(1);
      }, delay);
    }
    
    return () => {
      // eslint-disable-next-line
      !canvasRef.current && clearTimeout(autoPlayRef.current);
    }
  }, [autoPlay, autoPlayDelay, goToPage]);

  return (
    <Container>
      {!autoPlay && (
        <>
          {pageNumber > 1 && (<Button className="left" onClick={() => goToPage(-1)}><PrevButton/></Button>)}
          {pageCount > pageNumber && (<Button className="right" onClick={() => goToPage(1)}><NextButton/></Button>)}
        </>
      )}

      <Document file={`${MEDIA_PREFIX}${item?.src}`} onLoadSuccess={onDocumentLoadSuccess} loading="">
        <PdfPage pageNumber={pageNumber} canvasRef={canvasRef} scale={3} renderAnnotationLayer={false}/>
      </Document>
    </Container>
  )
}

export default PdfViewer;