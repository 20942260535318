import { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { showImageWhenLoaded, generateCSSFilters, getImageUrl, verifyEmsUrl } from '../common/Media';
import useFetchMedia from '../hooks/useFetchMedia';
import { MEDIA_PREFIX, WEBP_SUPPORT } from '../Settings';

interface MediaProps {
  media?: any,
  alt?: string,
  dimensions?: string,
  className?: string,
  style?: any,
  onClick?: any,
  usePlaceholder?: boolean,
  setMedia?: any,
  mediaType?: string,
  backgroundColor?: string
}

const ImageElement = styled.img.attrs((p: any) => ({
  focalX: p.focalX,
  focalY: p.focalY,
  scale: p.scale || 1,
  filters: p.filters
}))`
  object-fit: cover;
  opacity: 0;
  transition: opacity .5s;
  display: block;
  width: 100%;
  height: 100%;
  visibility: hidden;
  filter: ${p => p.filters};
  transform: translate3d(0, 0, 0);
  scale: ${p => p.scale || "none"};
  overflow: unset;
  ${p => p.scale === 1 && `object-position: ${p.focalX || 50}% ${p.focalY || 50}%;`}
  ${p => p.scale !== 1 && `transform-origin: ${p.focalX || 50}% ${p.focalY || 50}%;`}
`;

const ImageWrapper = styled.figure.attrs((p: any) => ({
  backgroundColor: p.backgroundColor
}))`
  background-color: ${p => p.backgroundColor || "#333"};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 25px;
  background-blend-mode: overlay;
  transition: background-color .5s;
  margin: 0;
  display: block;
  border-radius: 3px;
  flex-shrink: 0;
  overflow: hidden;

  &.link {
    cursor: pointer;
  }
`;

/**
 * Render an image. Used by most item components.
 * @returns {JSX.Element} Component template
 */
 const Image: FC<MediaProps> = ({ media, alt, className, dimensions, style, onClick, setMedia, usePlaceholder, mediaType, backgroundColor}) => {
  const imageRef = useRef<HTMLElement>(null);
  const [scaledUrl, setScaledUrl] = useState<string|undefined>();
  const [mediaObject] = useFetchMedia(media, { dimension: dimensions });
  const [filterCss, setFilterCss] = useState<string | undefined>(undefined);

  // Return media object to parent when data is loaded
  useEffect(() => {
    if (mediaObject?.url && setMedia) {
      setMedia(mediaObject);
    }
  }, [mediaObject, setMedia]);

  // Parse CSS filters from media object
  useEffect(() => {
    if (mediaObject?.filters) {
      setFilterCss(generateCSSFilters(mediaObject?.filters));
    }
  }, [mediaObject]);

  // Calculate image size based on container width and device pixel ratio
  useEffect(() => {
    const pixelRatio = window?.devicePixelRatio || 1;
    const elementWidth = pixelRatio >= 2 ? (imageRef?.current?.offsetWidth || 600) * 2 : (imageRef?.current?.offsetWidth || 600);
    let imgUrl = String(mediaObject?.thumbUrl || mediaObject?.url || mediaObject?.src).replace(/\?.*$/, '');
    if (imgUrl === "undefined") { return; }

    // Force use of EMS
    imgUrl = verifyEmsUrl(imgUrl);

    if (!dimensions) {
      if (elementWidth < 601) {
        imgUrl += `?dimension=600x600`;
      } else if (elementWidth < 801) {
        imgUrl += `?dimension=800x800`;
      } else if (elementWidth < 1201) {
        imgUrl += `?dimension=1200x1200`;
      } else {
        imgUrl += `?dimension=3500`;
      }
    } else {
      imgUrl += `?dimension=${dimensions}`;
    }


    if (mediaObject?.media_type) {
      imgUrl = getImageUrl(imgUrl);
    }

    // Force use of jpg if webp is not supported
    if (!WEBP_SUPPORT && !imgUrl.includes("mediaType")) {
      if (imgUrl.includes("?")) {
        imgUrl += `&mediaType=${"image/jpg"}`
      } else {
        imgUrl += `?mediaType=${"image/jpg"}`
      }
    }

    // Remove URL prefix if already been set
    if (MEDIA_PREFIX && !imgUrl.includes(MEDIA_PREFIX)) {
      imgUrl = imgUrl.replace(/MEDIA_PREFIX/g, "");
    }

    // Add URL prefix if not already present
    imgUrl = MEDIA_PREFIX + imgUrl;

    setScaledUrl(imgUrl);
  }, [mediaObject, mediaType, dimensions]);

  return (
    <ImageWrapper ref={imageRef} onClick={onClick} style={style} className={className} backgroundColor={backgroundColor}>
      {(scaledUrl && (mediaObject?.thumbUrl || mediaObject?.url || mediaObject?.src)) ? (<ImageElement alt={alt || ""} className={`loading`} onLoad={showImageWhenLoaded} src={`${scaledUrl}`} focalX={mediaObject?.focalPoint?.x} focalY={mediaObject?.focalPoint?.y} scale={mediaObject?.scale || 1} filters={filterCss}/>) : <ImageElement src="data:,"/>}
    </ImageWrapper>
  )
}

export default Image;