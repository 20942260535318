import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom'
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import BackgroundImage from '../components/BackgroundImage';
import MediaGalleryItem from '../components/MediaGalleryItem';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import ErrorMessageView from '../components/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { fetchDocument } from '../services/Api';
import useFetchDocument from '../hooks/useFetchDocument';
import { LightboxWrapper } from '../components/LightboxModal';
import { detectDomChanges } from '../common/Common';

const ListWrapper = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  list-style: none;
  margin: 0 ${p => p.theme.scaleFactor * 70}px ${p => p.theme.scaleFactor * 90}px;
  padding: ${p => p.theme.scaleFactor * 140}px 0 0px;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: start;
  align-content: space-evenly;
  grid-template-areas: none;
  grid-auto-flow: row dense; // column dense
  gap: 12px;
  scroll-snap-type: x mandatory;
  touch-action: pan-x;
  overflow: hidden;
  height: calc(100% - ${p => p.theme.scaleFactor * 60}px);

  &.snapScroll {
    overflow: scroll;
    scrollbar-width: auto;
    @supports not (-webkit-touch-callout: none) {
      @supports not (-moz-appearance:none) {
        height: 100%;
      }
    }
  }

  &.snapScroll li:nth-child(1n+1) {
    scroll-snap-align: end;
  }

  &.snapScroll::-webkit-scrollbar {
    @supports not (-webkit-touch-callout: none) {
      height: 90px;
    }
  }

  &.snapScroll::-webkit-scrollbar-thumb {
    border-bottom: 50px solid transparent;
    border-top: 30px solid transparent;
    background-color: ${p => p.theme.accentColor};
    background-clip: padding-box;
  }

  &.snapScroll::-webkit-scrollbar-track {
    margin-left: 0;
    margin-right: 0;
  }
`

interface MediaGalleryViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render a list of articles or media objects
 * @returns {JSX.Element} Component template
 */
const MediaGalleryView: FC<MediaGalleryViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const { documentId } = useParams<{documentId: string}>();
  const { currentLanguage } = useContext(AppContext);
  const listRef = useRef<HTMLUListElement>(null);
  const [columnCount, setColumnCount] = useState(3);
  const [rowCount, setRowCount] = useState(2);
  const [gridGap, setGridGap] = useState(20);
  const [gridFlow, ] = useState("column");
  const [titleColor, setTitleColor] = useState("#ffffff");
  const [subtitleColor, setSubtitleColor] = useState("#ffffff");
  const [titleSize, setTitleSize] = useState("1.5em");
  const [subtitleSize, setSubtitleSize] = useState("1em");
  const [textPosition, setTextPosition] = useState("bottom-left");
  const [textBackground, setTextBackground] = useState("shadow");
  const [mediaQueue, setMediaQueue] = useState<any>([]);
  const [data, error] = useFetchDocument(documentId, previewData);

  /**
   * Check if scrollbar should be visible
   */
  const checkScrollbarVisibility = useCallback(() => {
    setTimeout(() => {
      if (listRef.current) {
        listRef.current.classList.remove("snapScroll");
        if (listRef.current?.scrollWidth > listRef.current?.clientWidth) {
          listRef.current.classList.add("snapScroll");
        }
      }
    }, 100);
  }, []);

  // Collect all media ids from media list and article blocks
  useEffect(() => {
    let isSubscribed = true;
    if (!data) { return; }

    (async() => {
      let mediaList: any[] = [];
      data?.content?.media?.forEach((mediaItem) => {
        mediaList.push(mediaItem);
      });

      // Get media objects from articles
      if (data?.content?.records?.list) {
        for (const articleItem of data?.content?.records?.list) {
          // Fetch all articles
          await fetchDocument(articleItem?.to_document_id).then(articleData => {
            // Loop through all blocks
            if (articleData?.content?.content?.blocks?.[currentLanguage || "no"]) {
              articleData?.content?.content?.blocks?.[currentLanguage || "no"]?.forEach((block) => {
                if (block?.blockType === "media") {
                  // Loop through all media items in media blocks
                  block?.mediaItems?.forEach((mediaBlockItem) => {
                    mediaList.push(mediaBlockItem);
                  });
                }
              });
            }
          });
        }
      }

      // Save to mediaQueue when all media ids are collected
      isSubscribed && setMediaQueue(mediaList);
    })();

    return () => { isSubscribed = false; }
  }, [data, currentLanguage]);

  // Get the grid size. Can be received as a string ("2x3") or an object ({rows: 2, columns: 3})
  useEffect(() => {
    if (typeof data?.content?.settings?.grid?.gridSize === "string") {
      const gridSize = data?.content?.settings?.grid?.gridSize;
      setColumnCount(Number(gridSize?.split("x")?.[0] || columnCount))
      setRowCount(Number(gridSize?.split("x")?.[1] || rowCount))
    } else {
      setRowCount(data?.content?.settings?.grid?.rows || rowCount);
      setColumnCount(data?.content?.settings?.grid?.columns || columnCount);
    }

    //setGridFlow(data?.content?.settings?.grid?.flow || "column");
    checkScrollbarVisibility();
  }, [data?.content?.settings, columnCount, rowCount, checkScrollbarVisibility]);

  // Update grid parameters: rows, columns and grid gap
  useEffect(() => {
    if (listRef.current) {
      listRef.current.style.gridTemplateRows = `repeat(${rowCount}, calc(${100/rowCount}% - ${(rowCount * gridGap/rowCount) - (gridGap/rowCount)}px))`;
      listRef.current.style.gridTemplateColumns = `repeat(${columnCount}, calc(${100/columnCount}% - ${(gridGap * (columnCount - 1)/columnCount)}px))`;
      listRef.current.style.gridAutoColumns = `minmax(${100/columnCount}%, 1fr)`;
      listRef.current.style.gap = `${gridGap}px`;
      listRef.current.style.gridAutoFlow = `${gridFlow} dense`;
    }
  }, [rowCount, columnCount, gridGap, gridFlow]);

  // Should the scrollbar be visible?
  useEffect(() => {
    detectDomChanges(listRef, () => {
      checkScrollbarVisibility();
    });
  }, [checkScrollbarVisibility]);

  // Set grid and text states to be used in the MosaicItem component
  useEffect(() => {
    setGridGap(data?.content?.settings?.grid?.gap || gridGap);
    setTitleColor(data?.content?.settings?.text?.titleColor || titleColor);
    setSubtitleColor(data?.content?.settings?.text?.subtitleColor || titleColor);
    setTitleSize(data?.content?.settings?.text?.titleSize || titleSize);
    setSubtitleSize(data?.content?.settings?.text?.subtitleSize || subtitleSize);
    setTextPosition(data?.content?.settings?.text?.textPosition || textPosition);
    setTextBackground(data?.content?.settings?.text?.textBackground || textBackground);
  }, [data, rowCount, columnCount, gridGap, titleColor, subtitleColor, titleSize, subtitleSize, textPosition, textBackground]);

  // Do layout adjustments based on items count if grid size is not specified
  useEffect(() => {
    let rows = 0, cols = 0;
    const itemsCount = Object.values(mediaQueue)?.length;
    const gridSettings: any = data?.content?.settings?.grid;

    // If no row counter is specified
    if (isNaN(gridSettings?.rows)) {
      if (itemsCount <= 3) {
        rows = 1;
      } else {
        rows = 2;
      }

      listRef.current && setRowCount(rows);
    }

    // If no column counter is specified
    if (isNaN(gridSettings?.columns)) {
      if (itemsCount <= 3) {
        cols = itemsCount;
      } else if (itemsCount === 4) {
        cols = 2;
      } else {
        cols = 3;
      }

      listRef.current && setColumnCount(cols);
    }
  }, [data, rowCount, columnCount, mediaQueue]);

  // Listen for digit keypress
  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    const items = listRef.current && listRef.current.querySelectorAll<HTMLDivElement>("div.media");
    const closeButton = document.querySelector<HTMLButtonElement>("button.pswp__button--close");

    closeButton && closeButton.click();

    for (let i = 0; i <= 9; i++) {
      if (Number(key) === i){
        if (items && items[i]) {
          setTimeout(() => {
            (items && items[i]) && items[i]?.click();
          }, 500);
        }
      }
    }
  }, []);

  // Support keypress
  useEffect(() => {
    window.addEventListener('keyup', handleUserKeyPress);
  
    return () => {
      window.removeEventListener('keyup', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && data?.status !== "published") { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/> }

  return (
    <>
      <Header title={getTranslation(overrideTitle, currentLanguage) || (data?.content?.general?.showTitle ? getTranslation(data?.content?.general?.title, currentLanguage) : "")} backgroundColor={data?.content?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
        <BackgroundImage media={data?.content?.background?.image} backgroundColor={data?.content?.background?.backgroundColor} />
        <ListWrapper ref={listRef} className="snapScroll">
          <LightboxWrapper>
            {Object.values(mediaQueue)?.map((item, i) => {
              return <MediaGalleryItem key={`galleryìtem-${i}`} media={item} textPosition={textPosition} titleColor={titleColor} subtitleColor={subtitleColor} titleSize={titleSize} subtitleSize={subtitleSize} textBackground={textBackground} useOriginalMedia={Boolean(data?.content?.settings?.display?.useOriginalMedia === "true")}/>
            })}
          </LightboxWrapper>
        </ListWrapper>
      </PageTransition>
    </>
  );
}

export default MediaGalleryView;