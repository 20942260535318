import { useEffect, useRef, useState } from "react";
import { fetchDocument } from "../services/Api";

/**
 * Fetch article from the API
 * @param {number|string|undefined} id Document ID 
 * @param {object} previewData Preview object
 * @param {boolean} restartOnError Wait 3 seconds before load
 * @returns {object} Article object
 */
export default function useFetchDocument(id: number|string|undefined, previewData?: any, restartOnError?: Boolean) {
  const isSubscribed = useRef<boolean>(true);
  const lastId = useRef<string | number>();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastPreviewData, setLastPreviewData] = useState<any>(null);

  useEffect(() => {
    isSubscribed.current = true;

    if (!id || isNaN(Number(id)) || Number(lastId?.current) === Number(id) || previewData) { return; }
    setData({});
    setIsLoading(true);
    lastId.current = id;

    (async() => {
      fetchDocument(String(id)).then(document => {
        if (isSubscribed.current) {
          if (document) {
            setData(document);
          } else {
            if (restartOnError) {
              setTimeout(() => {
                window.location.reload();
              }, 5000);
            }
            setData({status: "error"});
          }
          
          setIsLoading(false);
        }
      }).catch(e => { 
        setError(e);
      });

      return () => { 
        isSubscribed.current = false;
        lastId.current = undefined;
      }
    })();
  }, [id, data, previewData, restartOnError]);

  useEffect(() => {
    if (previewData) {
      // Avoid rerendering if the same data is received
      if (lastPreviewData === previewData) { return; }
      setLastPreviewData(previewData);
      setData({content: previewData, status: "published"});
    }
  }, [previewData, lastPreviewData]);

  if (!id && !previewData) {
    return [null, null, false];
  }

  return [data, error, isLoading]
}