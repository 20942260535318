import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage } from 'react-compare-slider';
import { AppContext } from '../contexts/AppContext';
import { getTranslation } from '../common/Translation';
import { fetchMedia } from '../services/Api';
import BlockContainer from './BlockContainer';

interface BlockImageCompareProps {
  elements?: any
}

const BlockContain = styled(BlockContainer)`
  flex-direction: column;

  & > div:not(:last-of-type) {
    margin-bottom: 30px;
  }

  & > div {
    max-height: 70vh;
    border-radius: 8px;
  }

  & img {
    max-height: 70vh;
  }
`

// Define component handle style
const CompareHandle = () => (
  <ReactCompareSliderHandle buttonStyle={{ alignItems: "center", backgroundColor: "#a50c30", border: "none"}}/>
)

/**
 * Render an error message. Supports markdown
 * @returns {JSX.Element} Component template
 */
const BlockImageCompare: FC<BlockImageCompareProps> = ({elements}) => {
  const { currentLanguage } = useContext(AppContext);
  const [imageList, setImageList] = useState<any>([]);

  // Get media objects from API
  useEffect(() => {
    let isSubscribed = true;
    let imageArray: any[] = [];

    (async() => {
      await Promise.all(elements?.map(async (element: any, i: number) => {
        if (isSubscribed) {
          imageArray[i] = await fetchMedia(String(element?.mediaId));
          if (imageArray[i]?.media_type === "video") { 
            imageArray[i].src = imageArray[i]?.thumbnail_src
          }
        }
      }));

      setImageList(imageArray);
    })();

    return () => { isSubscribed = false };
  }, [elements]);

  // Render list of compare sliders
  const renderComparableImages = useMemo(() => {
    let compareList: JSX.Element[] = [];
    for (let i = 0; i < imageList.length; i+=2) {
      if (imageList?.[i+1]) {
        compareList.push(
          <ReactCompareSlider key={`compare${i}`} handle={<CompareHandle/>}
            itemOne={<ReactCompareSliderImage src={(imageList?.[i]?.src || imageList?.[i]?.url) + "?dimension=1200x1200"} alt={getTranslation(imageList?.[i]?.title, currentLanguage)} />}
            itemTwo={<ReactCompareSliderImage src={(imageList?.[i+1]?.src || imageList?.[i+1]?.url) + "?dimension=1200x1200"} alt={getTranslation(imageList?.[i+1]?.title, currentLanguage)} />} />
        );
      }
    }

    return compareList;
  }, [imageList, currentLanguage]);

  // Return null if ResizeObserver is not supported. Relevant to iOS versions older than v13.4
  if (typeof ResizeObserver === "undefined") {
    return null;
  }

  return (
    <BlockContain>
      {renderComparableImages}
    </BlockContain>
  );
}

export default BlockImageCompare;