import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import { getTranslation } from '../common/Translation';
import ErrorMessageView from '../components/ErrorMessage';
import { AppContext } from '../contexts/AppContext';
import useFetchDocument from '../hooks/useFetchDocument';
import { fetchDocument } from '../services/Api';
import ArticleView from './Article';


interface ArticleViewProps {
  previewData?: any,
  overrideTitle?: string
}

/**
 * Render a single article
 * @returns {JSX.Element} Component template
 */
const SingleView: FC<ArticleViewProps> = ({previewData=null, overrideTitle}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(AppContext);
  const { documentId } = useParams<{documentId: string}>();
  const [articleData, setArticleData] = useState<any>();
  const [data, error] = useFetchDocument(documentId, previewData);
  
  // Get article data from the API
  useEffect(() => {
    let isSubscribed = true;

    fetchDocument(data?.content?.records?.list?.[0]?.to_document_id).then(data => {
      isSubscribed && setArticleData(data?.content);
    });

    return () => { isSubscribed = false; }
  }, [documentId, previewData, data?.content?.records?.list]);

  // Display error message if no content is loaded
  if (error) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("presentationNotFound.body")} redirect={true}></ErrorMessageView> }

  // Only display if published
  if (data?.status && !data?.status?.includes("published")) { return <ErrorMessageView title={t("presentationNotPublished.title")} body={t("presentationNotPublished.body")} redirect={true}/>; }

  return (
    <>
      <ArticleView previewData={articleData} overrideTitle={getTranslation(overrideTitle, currentLanguage) ||( data?.content?.general?.showTitle ? data?.general?.title : false)}/>
    </>
  );
}

export default SingleView;