import { FC } from 'react';
import styled from 'styled-components';
import packageJson from '../../package.json'
import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import PageTransition from '../components/PageTransition';

const Wrapper = styled.main`
  background: #1c1a1a url("/img/splashbackground.jpg") no-repeat 50% 50%; 
  background-size: cover; 
  color: #fff; 
  height: 100%; 
  width: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const Container = styled.section`
  width: 540px;
  text-align: center;
  padding: 50px 0 40px;
  overflow: hidden;
  position: relative; 
  border-radius: 5px;
` 

const Icon = styled.div`
  width: 100px; 
  height: 100px; 
  margin: 0 auto;

  & svg {
    width: 100px; 
    height: 100px; 
    margin-bottom: 25px; 
    border-radius: 5px;
  }
`

const Title = styled.h1`
  font-size: 1.8em;
  font-weight: 300;
`

const Version = styled.span`
  font-size: .9em; 
  color: #ddd;
`

/**
 * Render a splash page
 * @returns {JSX.Element} Component template
 */
const SplashScreen: FC = () => {

  return (
    <PageTransition>
      <Wrapper>
        <Container>
          <Icon><LogoIcon/></Icon>
          <Title>{packageJson.displayName}</Title>
          <Version>{packageJson.version}</Version>
        </Container>
      </Wrapper>
    </PageTransition>
  );
}

export default SplashScreen;