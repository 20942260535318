import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from '../common/Date';
import { getTranslation } from '../common/Translation';
import { BlockMediaItem, BlockMediaWrapper } from '../components/BlockMedia';
import BlockText from '../components/BlockText';
import BlockTitle from '../components/BlockTitle';
import PageTransition from '../components/PageTransition';
import { AppContext } from '../contexts/AppContext';
import { ReactComponent as MinnenLogo } from "../assets/logo_minnen.svg";
import { ReactComponent as MinnerLogo } from "../assets/logo_minner.svg";
import { fetchMinneContent } from '../services/Api';
import Header from '../framework/Header';
import ErrorMessageView from '../components/ErrorMessage';
import BlockContainer from '../components/BlockContainer';

interface MinneViewProps {
  country: "no" | "sv"
}

const ArticleWrapper = styled.article`
  position: relative;
  padding: ${p => p.theme.scaleFactor * 80}px 0 0;
  margin: ${p => p.theme.scaleFactor * 120}px auto 0;
  z-index: 1;

  &.preview * {
    pointer-events: none;
  }
`;

const Metadata = styled.table`
  border-collapse: collapse;
  padding: 0 25px;
  width: fit-content;
`;

const TableRow = styled.tr`
  & td:first-of-type {
    font-weight: bold;
  }
`;

const TableCol = styled.td`
  padding: 5px 25px 5px 0;
`;

const Footer = styled.div`
  margin: 0 0 30px 0;
  padding: 20px 25px 0;
  text-align: center;
  border-top: 1px solid #00000010;

  & p {
    margin-bottom: 5px;
  }
`;

/**
 * Render the Minne page
 * @returns {JSX.Element} Component template
 */
const MinneView: FC<MinneViewProps> = ({country}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useContext(AppContext);
  const { responseId } = useParams<{responseId: string}>();
  const [minneData, setMinneData] = useState<any>(null)

  // Fetch response from the Minne API
  useEffect(() => {
    let isSubscribed = true;

    fetchMinneContent(`response/${responseId}`, country).then(data => {
      if (isSubscribed) {
        setMinneData(data);
      }
    }).catch(e => {});

    return () => { isSubscribed = false }
  }, [responseId, country]);

  // Update title
  useEffect(() => {
    document.title = `${getTranslation(minneData?.topic?.name_i18n, currentLanguage || "no")} - KulturPunkt`;
  }, [minneData, currentLanguage]);

  // Was the data not found?
  if (minneData && !minneData?.topic) { return <ErrorMessageView title={t("presentationNotFound.title")} body={t("404.body")} redirect={true}></ErrorMessageView> }

  return (
    <>
      <Header title={getTranslation(minneData?.topic?.name_i18n, currentLanguage || "no")}></Header>
      <PageTransition waitFor={minneData} scroll={true}>
        <ArticleWrapper>
          {minneData?.values?.map((value: any, i: number) => {
            return (<React.Fragment key={`block${i}`}>
              <BlockTitle level="h2" text={getTranslation(value?.topic_item?.label_i18n, currentLanguage || "no")} />
              {["select", "radio", "checkbox"].includes(value?.topic_item?.type?.toLocaleLowerCase()) ? (
                <BlockText text={getTranslation(value?.option_labels?.[0]?.[0], currentLanguage) || ""}/>
              ) : (
                <BlockText text={value?.display_value || ""}/>
              )}
            </React.Fragment>);
          })}

          {minneData?.media?.[0] && (
            <BlockMediaWrapper layout="full" lightbox={true}>
              {minneData?.media?.map((value: any, i: number) => {
                return <BlockMediaItem key={`media${i}`} media={{dmsId: value?.dms_id, media_type: "image"}} caption={value?.description} lightbox={true}/>
              })}
            </BlockMediaWrapper>
          )}

          <BlockContainer>
            <Metadata>
              <tbody>
                <TableRow>
                  <TableCol>{t("minne.published")}</TableCol>
                  <TableCol>{formatDate(minneData?.created)}</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>{t("minne.author")}</TableCol>
                  <TableCol>{minneData?.contributor?.display_name}</TableCol>
                </TableRow>
                <TableRow>
                  <TableCol>{t("minne.archiveCode")}</TableCol>
                  <TableCol>{minneData?.archive_code}</TableCol>
                </TableRow>
              </tbody>
            </Metadata>
          </BlockContainer>
        </ArticleWrapper>

        <Footer>
          <p>{t("minne.contentFrom")}</p>
          {country === "no" && (<MinnerLogo/>)}
          {country === "sv" && (<MinnenLogo/>)}
        </Footer>
      </PageTransition>
    </>
  );
}

export default MinneView;
