import { FC, useCallback, useContext, useEffect, useState } from 'react';
import Link from './Link';
import styled from 'styled-components';
import { formatDate } from '../common/Date';
import { AppContext } from '../contexts/AppContext';
import { fetchMinneContent } from '../services/Api';
import BlockContainer from './BlockContainer';
import BlockError from './BlockError';
import Image from './Image';
import Pagination from './Pagination';
import { getTranslation } from '../common/Translation';

interface BlockMinneProps {
  topicId: any,
  domain?: string,
  sortOrder?: string,
  resultCount?: number
}

const Responses = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  align-content: space-between;
  gap: 15px;

  &:empty {
    display: none;
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Response = styled.li`
  border-radius: 8px;
  cursor: pointer;
  min-height: 110px;
  background-color: #ffffff15;
  color: #fff;
  box-shadow: 0 2px 0 0 rgb(0 0 0 / 20%);
  display: flex;
  align-items: center;

  &:active {
    filter: brightness(.95);
  }

  & a {
    text-decoration: none;
    color: ${p => p.theme.textColor};
    width: 100%;
  }
`;

const ResponseContainer = styled.div`
  padding: ${p => p.theme.scaleFactor * 25}px;
  display: flex;
  align-items: center;
  width: 100%;
  display: block;
  float: left;
`;

const ThumbImage = styled(Image)`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin: 0 ${p => p.theme.scaleFactor * 20}px 0 0;

  & img {
    border-radius: 50%;
  }
`

const Metadata = styled.div`
`;

const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  --max-lines: 2;
   display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const Author = styled.span`
  margin-top: 10px;
  display: block;
  font-size: .9em;
`;

/**
 * Render a Minne block
 * @returns {JSX.Element} Component template
 */
 export const BlockMinne: FC<BlockMinneProps> = ({topicId, domain, sortOrder, resultCount}) => {
  const { previewIsActive, currentLanguage } = useContext(AppContext);
  const [minneData, setMinneData] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(resultCount || 12);
  const [countryCode, setCountryCode] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);

  /**
   * Decode HTML entities
   * @param {string} str String to process. Example: "H&#234;ne"
   * @returns {string} Returns a decoded string. Example: "Høne"
   */
  const decodeHTMLEntities = useCallback((str: string) => {
    if (typeof str !== "string") { return "" }

    return str?.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }, []);

  /**
   * Fetch responses from the Minne API
   */
  const fetchMinneResponses = useCallback((topicId: string, country: string) => {
    return new Promise(function(resolve, reject) {
      fetchMinneContent(`responses/?topic=${topicId}&offset=${pageSize * (currentPage - 1)}&limit=${pageSize}&order=${sortOrder || ""}`, country).then(data => {
        if (data?.items) {
          setMinneData(data);
          setCountryCode(country);
        }
        resolve(data);
      }).catch(e => {
        reject();
      });
    });
  }, [currentPage, pageSize, sortOrder]);

  // Update page size
  useEffect(() => {
    if (resultCount) { 
      setPageSize(resultCount);
    } else {
      setPageSize(12);
    }
  }, [resultCount]);
  
  // Query topic data from Minne.
  useEffect(() => {
    if (!topicId || topicId?.length !== 36) { return; }

    if (domain) {
      const languageCode = domain.includes(".se") ? "sv" : "no";

      fetchMinneResponses(topicId, languageCode).then((data: any) => {
        if (data?.items) {
          setHasError(false);
        } else {
          setHasError(true);
        }
      }).catch(() => {
        setHasError(true);
      });
    } else {

    // -- Old method: First try norwegian, and then if not found - try swedish
    // Try norwegian
    fetchMinneResponses(topicId, "no").then((data: any) => {
      if (data?.items) {
        setHasError(false);  
      } else {
        // Then try swedish
        fetchMinneResponses(topicId, "sv").then((data: any) => {
          if (data?.items) {
            setHasError(false);
          } else {
            setHasError(true);
          }
        }).catch(() => {
          setHasError(true);
        });
      }
    }).catch(() => {
      setHasError(true);
    });
    }
  }, [topicId, domain, fetchMinneResponses]);

  if (!topicId) { return null; }

  if (previewIsActive && (topicId?.length !== 36 || hasError)) {
    return <BlockError text={`Kunne ikke hente data fra Minne-APIet for topicId '${topicId}'.`}></BlockError>;
  }

  return (
    <BlockContainer>
      {minneData?.items && (
        <Responses>
        { minneData?.items?.map((response: any, i: number) => { 
          const createdTime = Date.parse(response?.created);

          return <Response key={`response${i}`}>
            <Link presentationType={countryCode === "no" ? "minner" : "minnen"} id={`${response?.uuid}`}>
              <ResponseContainer>
                {response?.image_dms_id && (<ThumbImage media={{dmsId: response?.image_dms_id, media_type: "image"}}/>)}
                <Metadata>
                  <Text>
                    {["select", "radio", "checkbox"].includes(response?.values?.[0]?.topic_item?.type?.toLocaleLowerCase()) ? (
                      <>{getTranslation(decodeHTMLEntities(response?.values?.[0]?.option_labels?.[0]?.[0]), currentLanguage) || ""}</>
                    ) : (
                      <>{decodeHTMLEntities(response?.values?.[0]?.display_value || "")?.replace( /(<([^>]+)>)/ig, '') || ""}</>
                    )}
                  </Text>
                  <Author>{response?.contributor?.display_name?.length > 0 && `${response?.contributor?.display_name}, `}{formatDate(createdTime)}</Author>
                </Metadata>
              </ResponseContainer>
            </Link>
          </Response>
        })}
      </Responses>
      )}
      <Pagination currentPage={currentPage} itemCount={minneData?.total_count} pageSize={pageSize} setCurrentPage={setCurrentPage}  />
    </BlockContainer>
  );
}