import { FC } from 'react';
import Header from '../framework/Header';
import { useTranslation } from "react-i18next";
import ErrorMessageView from '../components/ErrorMessage';
import { useLocation } from 'react-router-dom';

/**
 * Render a 404 page
 * @returns {JSX.Element} Component template
 */
const NotFoundView: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Ignore 404 on iframe pages
  if (location.pathname?.includes("iframe")) { return null }

  return (
    <>
      <Header></Header>
      <ErrorMessageView title={t("404.title")} body={t("404.body")}/>
    </>
  );
}

export default NotFoundView;
