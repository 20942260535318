import { FC, useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import styled from 'styled-components';
import Header from '../framework/Header';
import BackgroundImage from '../components/BackgroundImage';
import HomeScreenItem from '../components/HomeScreenItem';
import { getTranslation } from '../common/Translation';
import PageTransition from '../components/PageTransition';
import MosaicView from './Mosaic';

const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  padding: 0 0 0 100px;

  & li:last-child {
    padding-right: 100px;
  }

  &:has(li:nth-child(1):last-child), &:has(li:nth-child(2):last-child), &:has(li:nth-child(3):last-child), &:has(li:nth-child(4):last-child, &:has(li:nth-child(5):last-child)) {
    justify-content: center;
  }

  &.manyItems {
    justify-content: flex-start;
  }
`

interface HomeScreenViewProps {
  data?: any,
  layout?: string
}

/**
 * Render the home screen
 * @returns {JSX.Element} Component template
 */
const HomeScreenView: FC<HomeScreenViewProps> = ({data, layout}) => {
  const { currentLanguage } = useContext(AppContext);

  if (layout !== "menu") {
    // Transform device data to support view
    let recordData = data?.presentations?.list?.map((record: any) => {
      return {...record, ...{content: { ...record.content, image: record.content.icon }}};
    });
    let viewData = {...data, ...{records: { list: recordData }}};
    return <MosaicView previewData={viewData}/>
  }

  return (
    <>
      <Header title={data?.general?.showTitle ? getTranslation(data?.general?.title, currentLanguage) : ""} backgroundColor={data?.background?.backgroundColor}></Header>
      <PageTransition waitFor={data}>
        <BackgroundImage media={data?.background?.image} backgroundColor={data?.background?.backgroundColor} />
        <Menu className={data?.presentations?.list?.length > 5 ? "manyItems" : "fewItems"}>
          {data?.presentations?.list?.map((item, i) => {
            return <HomeScreenItem key={`homeitem-${i}`} documentId={item?.to_document_id} icon={item?.content?.icon} title={item?.content?.title} backgroundColor={data?.background?.backgroundColor}/>
          })}
        </Menu>
      </PageTransition>
    </>
  );
}

export default HomeScreenView;