import { FC } from 'react';
import styled from 'styled-components';
import BlockContainer from './BlockContainer';
import Image from './Image';

interface BlockBylineProps {
  data: any
}

const BlockBylineWrapper = styled.div`
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ProfileImage = styled(Image)`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 15px;

  & img {
    border-radius: 50%;
  }
`;

const Metadata = styled.div`
`;

const Name = styled.span`
  font-weight: bold;
  clear: both;
  display: block;
  margin-bottom: 5px;
`;

const Description = styled.span`
  clear: both;
  display: block;
  margin-bottom: 5px;
`;

/**
 * Render a byline block
 * @returns {JSX.Element} Component template
 */
 export const BlockByline: FC<BlockBylineProps> = ({data}) => {

  if (!data?.name) { return null; }
  if (!data?.prefix) { data.prefix = ""; }

  return (
    <BlockContainer>
      <BlockBylineWrapper>
        {data?.image?.mediaId && (<ProfileImage media={data?.image}/>)}
        <Metadata>
          <Name>{(data?.prefix && String(data?.prefix) + " ") + String(data?.name)}</Name>
          {data?.description && (<Description>{data?.description}</Description>)}
        </Metadata>
      </BlockBylineWrapper>
    </BlockContainer>
  );
}